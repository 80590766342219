import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { BsArrowRight } from "react-icons/bs";
import DropdownIcon from "../../../GlobalComponent/DropdownIcon/DropdownIcon";
import Portfolio from "../../../Images/Portfolio.jpeg";
import autocarindia from "../../../Images/autocarindia.jpeg";
import img from "../../../Images/img.png";
import GEHealthcare from "../../../Images/GEHealthcare.jpeg";
import { Link } from "react-router-dom";
const defaultstories = [
  {
    title:
      "Ensuring Budget Discipline Amid Evolving Conditions for a Global IT Leader",
    sector: "SOFTWARE & TECHNOLOGY",
    year: "2024",
    image: img,
    link: "/stories/thoughtfocus",
  },
  {
    title:
      "Crafting a Luxurious Experience: Interior Design for the Mercedes-Benz Showroom in Bangalore",
    sector: "COMMERCIAL & RETAIL",
    year: "2023",
    image:
      "https://mailstone.s3.us-east-1.amazonaws.com/Images/autocarindia.jpeg",
    link: "/stories/mercedesbenz",
    // image:
    //   "https://cdn.prod.website-files.com/6650989eef5b5b85680d0708/66daf613dd0f55d13a4ad92d_Mobileye_Case%20Study_thumbnail-p-500.avif",
  },
  {
    title:
      "Advanced Commercial Interior Solutions for GE Healthcare: Specialized Medical Systems with Vibration Rooms and Lead-Lined Installations",
    sector: "HEALTHCARE",
    year: "2023",
    image:
      "https://mailstone.s3.us-east-1.amazonaws.com/Images/GEHealthcare.jpeg",
    link: "/stories/gehealthcare",
  },
];
const SuccessStories = () => {
  const [isHovered, setIsHovered] = React.useState(false);
  return (
    <Container className="SuccessStories-container py-5">
      <h1 className="SuccessStories-title display-4 mb-5">Success Stories</h1>

      <Row className="SuccessStories-row g-4">
        {defaultstories.map((story, index) => (
          <Col key={index} lg={4} md={6} className="SuccessStories-col">
            <Card className="SuccessStories-card border-0 h-100 position-relative overflow-hidden">
              <div
                className="SuccessStories-image-wrapper"
                style={{ height: "520px" }}
              >
                <Card.Img
                  variant="top"
                  src={story.image}
                  className="SuccessStories-image h-100 w-100 object-fit-cover"
                />
              </div>
              <Link to={story.link} className="text-decoration-none text-black">
                <Card.Body
                  onMouseEnter={() => setIsHovered(index)}
                  onMouseLeave={() => setIsHovered(false)}
                  className={`SuccessStories-card-overlay position-absolute w-100 p-4 bg-white m-4 bottom-0 start-0 translate-x-1/2'
                }`}
                  // ${index === 1 ? 'start-0 top-0' : '
                  style={{ maxWidth: "calc(100% - 2rem)" }}
                >
                  <div className="SuccessStories-card-content">
                    <div className="SuccessStories-card-header d-flex justify-content-between align-items-start mb-4">
                      <h3 className="SuccessStories-card-title h5 mb-0 pe-4">
                        {story.title}
                      </h3>
                      <div
                        style={{
                          aspectRatio: "1/1",
                        }}
                      >
                        <DropdownIcon
                          v2={true}
                          isHovered={isHovered === index}
                        />
                      </div>
                      {/* <BsArrowRight className="SuccessStories-arrow ms-2 flex-shrink-0" size={24} /> */}
                    </div>
                    <div className="SuccessStories-metadata d-flex justify-content-between text-uppercase">
                      <div className="SuccessStories-sector">
                        <small className="text-muted d-block mb-1">
                          SECTOR
                        </small>
                        <span className="fw-medium">{story.sector}</span>
                      </div>
                      <div className="SuccessStories-year">
                        <small className="text-muted d-block mb-1">YEAR</small>
                        <span className="fw-medium">{story.year}</span>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Link>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default SuccessStories;
