import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import company2 from "../Images/company2.jpg"

const VestianSection = () => {
  return (
    <Container className="py-5">
      <Row className="vestian-row align-items-center flex-column-reverse flex-lg-row">
        <Col lg={6} className="vestian-text">
          <h2 className="vestian-headline">
            At Mailstone, we focus on providing comprehensive commercial real
            estate solutions tailored specifically for businesses
          </h2>
          <p className="vestian-description">
            At Mailstone, we integrate strategic planning with precise execution
            to offer a comprehensive range of services all under one roof.
            Whether optimizing global portfolios or designing workspaces that
            enhance productivity, we create tailored strategies that go beyond
            meeting your business expectations. By harnessing advanced
            technology and market insights, we proactively anticipate trends and
            mitigate risks, ensuring your commercial interiors strategy stays
            adaptable and future-ready. Partnering with Mailstone ensures your
            workspace evolves with your business needs.{" "}
          </p>
        </Col>
        <Col lg={6} className="vestian-image">
          <Image
            src={company2}
            alt="Office team working"
            fluid
            className="vestian-team-image"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default VestianSection;
