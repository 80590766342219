import React, { useState } from "react";
import "./AllServiceCard.css";
import { Container, Row } from "react-bootstrap";
import SideArrow from "../SideArrow/SideArrow";
import { Link } from "react-router-dom";

const AllServiceCard = ({name,link}) => {
    const [isHovered, setIsHovered] = useState(false);
  return (
    <Link to={link} className="text-black text-decoration-none">
    <Container className="allServiceCard d-flex flex-column">
      <Row className="card-header">
        <h1 className="mt-4">{name}</h1>
      </Row>
      <Row className="card-body flex-grow-1 d-flex align-items-end">
        <div className="CardFooter text-decoration-underline">
          <p>Learn More</p>
          <div
            className=""
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <p>
              <SideArrow isHovered={isHovered} />
            </p>
          </div>
        </div>
      </Row>
    </Container>
    </Link>
  );
};

export default AllServiceCard;
