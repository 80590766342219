import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import DropdownIcon from "../DropdownIcon/DropdownIcon";
import "./ReadyToTalk.css";
import { Link } from "react-router-dom";
const ReadyToTalk = () => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div className="py-5 ">
      <Container className="mb-5">
        <Row>
          <Col>
            {/* <Link to=""> */}
              <img
                src="https://res.cloudinary.com/dbklt3v4d/image/upload/v1719306812/mailStone/bu7trgfdi4qyxyelonlc.png"
                alt="Mailstone"
                style={{
                  maxWidth: "100px",
                }}
                className=""
              />
            {/* </Link> */}
          </Col>
          <Col>
            <h3 className="ReadyToTalk-header fw-bolder">
              Crafting interiors that work for you
            </h3>
          </Col>
        </Row>
      </Container>
      <Container
        style={{ backgroundColor: "#0D3333" }}
        className="py-5 mt-4 rounded-3"
      >
        <div className="d-flex justify-content-center align-content-between">
          <div className="w-50 ReadyToTalk-header py-3 d-flex text-white justify-content-center align-content-between">
            Ready to talk?
          </div>
          <div className="w-50 d-flex justify-content-center align-items-center">
            <Link
              style={{
                textDecoration: "none",
              }}
              to={"/contactus"}
            >
              <Button
                onMouseEnter={() => {
                  setIsHovered(true);
                }}
                onMouseLeave={() => {
                  setIsHovered(false);
                }}
                className="d-flex justify-content-center align-items-center"
              >
                Get in Touch
                <DropdownIcon v2={true} isHovered={isHovered} />
              </Button>
            </Link>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ReadyToTalk;
