import React from "react";
import IndustriesHeroComp from "../Component/IndustriesHeroComp/IndustriesHeroComp";
import SecondComponent from "../Component/SecondComponent/SecondComponent";
import ThirdCompnent from "../Component/ThirdCompnent/ThirdCompnent";
import RelatedArticle from "../../Services/Component/RelatedArticle/RelatedArticle";
import officeimg from "../../Images/office.jpg"

const officeData = {
  Textinjsx: "Office",
  industriesView: [
    "View All Industries",
    "People working in a modern tech office on their laptops.",
  ],
  img:officeimg,
  leftText: "Transform your workplace to enhance your business potential.",
  description: `The modern office is not just a workspace—it’s a strategic tool for attracting top talent, promoting collaboration, and driving productivity. We collaborate with businesses to design office environments that reflect your culture and support your objectives. From flexible layouts that evolve with your needs to tech-driven spaces that enhance efficiency, we’ll assist you in designing, acquiring, and managing office spaces that provide your team with a competitive advantage in today’s dynamic marke`,
  services: [
    {
      title: "Workplace Solutions & Space Optimization Strategy",
      details:
        "Turn your office into a strategic advantage. We design adaptable, efficient workspaces that boost productivity, encourage collaboration, and evolve with your organization’s needs. Using a data-driven approach, we ensure your office aligns with your business goals while prioritizing employee well-being.",
    },
    {
      title: "Strategic Portfolio Planning & Optimization",
      details:
        "Strategic Portfolio Planning & Optimization Unlock the full potential of your office portfolio. We align your real estate assets with your business objectives, optimizing space utilization and reducing costs across all locations. Our tailored solutions drive growth, enhance operational flexibility, and strengthen your bottom line.",
    },
    {
      title: "Transaction Management",
      details:
        "Confidently navigate office leasing with our expert support. We offer unbiased guidance and skilled negotiation to secure terms that align with your business priorities. Backed by thorough market analysis and lease structuring expertise, we ensure your office space solutions are both flexible and cost-effective.",
    },
  ],
};

const Office = () => {
  return (
    <>
      <IndustriesHeroComp
        Textinjsx={officeData.Textinjsx}
        img={officeData.img}
      />
      <SecondComponent
        leftText={officeData.leftText}
        rightText={officeData.description}
      />
      <ThirdCompnent
        heading={"Customized Office Real Estate Solutions Aligned with Your Business Objectives"}
        sections={officeData.services}
      />
      <RelatedArticle />
    </>
  );
};

export default Office;
