import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "react-multi-carousel/lib/styles.css";
import "../style.css";
import Carousel from "react-multi-carousel";
import DropdownIcon from "../../../GlobalComponent/DropdownIcon/DropdownIcon";

const defaultarticles = [
  {
    category: "INSIGHTS",
    date: "1 JANUARY",
    title: "Together, we're building a brighter future—one product at a time.",
    description:
      "ease flexibility has become a key strategic priority for businesses, offering adaptable office environments, efficient cost management, and the ability to swiftly adjust to economic fluctuations.",
    image: "https://mailstone.s3.us-east-1.amazonaws.com/Images/DSC_0220.JPG",
  },
  {
    category: "NEWS",
    date: "6 JUNE",
    title: "Our Workforce: The Pillars of Our Success",
    description:
      "Our workforce is the driving force behind everything we do. Every day, our dedicated team works tirelessly to ensure that we deliver high-quality products and services to our clients. We take pride in the expertise, passion, and commitment our employees bring to the workplace, making them a vital part of our factory operations.",
    image: "https://mailstone.s3.us-east-1.amazonaws.com/Images/DSC_0229.JPG",
  },
  {
    category: "INSIGHTS",
    date: "15 DECEMBER",
    title: "Trends Shaping Modern Workplaces",
    description:
      "Optimizing office environments through innovative design and technology to promote collaboration and productivity in the evolving workplace.",
    image: "https://mailstone.s3.us-east-1.amazonaws.com/Images/DSC_0183.JPG",
  },
];

const RelatedArticle = ({ articles = defaultarticles }) => {
  const [isHovered, setIsHovered] = useState(false);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const CustomButtonGroup = ({ next, previous }) => {
    const [isHovered, setIsHovered] = useState(false);
    return (
      <div className="GlobalLeaderShip-buttons position-absolute bottom-0 start-0 d-flex gap-2 mb-4">
        <button
          className="GlobalLeaderShip-button"
          onClick={previous}
          aria-label="Previous slide"
          onMouseEnter={() => setIsHovered("previous")}
          onMouseLeave={() => setIsHovered(false)}
        >
          <DropdownIcon v3={true} isHovered={isHovered === "previous"} />
        </button>
        <button
          className="GlobalLeaderShip-button"
          onClick={next}
          aria-label="Next slide"
          onMouseEnter={() => setIsHovered("next")}
          onMouseLeave={() => setIsHovered(false)}
        >
          <DropdownIcon v2={true} isHovered={isHovered === "next"} />
        </button>
      </div>
    );
  };

  return (
    <Container className="PortfolioCarousel-container py-5 position-relative">
      <h2 className="PortfolioCarousel-title display-4 mb-5">
        Related Articles
      </h2>
      <Row className="PortfolioSolutions-featured mb-5">
        <Col md={6} className="mb-4 mb-md-0">
          <div
            className="PortfolioSolutions-featured-image"
            style={{ height: "400px" }}
          >
            <img
              src={articles[0].image}
              alt={articles[0].title}
              className="w-100 h-100 object-fit-cover rounded-3"
            />
          </div>
        </Col>
        <Col md={6} className="d-flex flex-column justify-content-center">
          <div className="d-flex">
            <div
              className="PortfolioSolutions-badge mb-4 my-auto"
              style={{
                backgroundColor: "#0000FF",
                color: "white",
                padding: "7px",
                display: "inline-block",
                fontSize: "14px",
              }}
            ></div>
            <div
              className="PortfolioSolutions-badge mb-4 my-auto"
              style={{
                padding: "4px 12px",
                display: "inline-block",
                borderRadius: "4px",
                fontSize: "14px",
              }}
            >
              {articles[0].category}
            </div>
          </div>
          <h3 className="PortfolioSolutions-featured-title h1 mb-4">
            {articles[0].title}
          </h3>
          <p className="PortfolioSolutions-description text-muted mb-4">
            {articles[0].description}
          </p>
        </Col>
      </Row>

      <Carousel
        responsive={responsive}
        infinite={true}
        customButtonGroup={<CustomButtonGroup />}
        arrows={false}
        renderButtonGroupOutside={true}
        className="PortfolioCarousel-wrapper"
      >
        {articles.map((article, index) => (
          <div key={index} className="PortfolioCarousel-item px-3">
            <div className="PortfolioCarousel-image-wrapper mb-4">
              <img
                src={article.image}
                alt={article.title}
                className="PortfolioCarousel-image w-100 h-100 object-fit-cover rounded-3"
              />
            </div>
            <div className="PortfolioCarousel-content">
              <div className="d-flex align-items-center gap-3 mb-3">
                <div
                  style={{
                    width: "14px",
                    height: "14px",
                    backgroundColor: "#0000FF",
                  }}
                ></div>
                <div className="PortfolioCarousel-badge">
                  {article.category}
                </div>
                <span className="PortfolioCarousel-date">{article.date}</span>
              </div>
              <h3 className="PortfolioCarousel-item-title h5 mb-3">
                {article.title}
              </h3>
              <p className="PortfolioCarousel-description text-muted">
                {article.description}
              </p>
            </div>
          </div>
        ))}
      </Carousel>
    </Container>
  );
};

export default RelatedArticle;
