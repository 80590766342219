import React from "react";
import IndustriesHeroComp from "../Component/IndustriesHeroComp/IndustriesHeroComp";
import SecondComponent from "../Component/SecondComponent/SecondComponent";
import ThirdCompnent from "../Component/ThirdCompnent/ThirdCompnent";
import RelatedArticle from "../../Services/Component/RelatedArticle/RelatedArticle";
import img from "../../Images/healthcare-g.jpg"

const healthcareData = {
  Textinjsx: "Healthcare",
  industriesView: [
    "View All Industries",
    "A team of nurses and doctors gathered around a nursing station, reviewing patient charts and discussing care plans.",
  ],
  img: img,
  leftText: "Turn your facilities into drivers of improved care.",
  description: `Align your healthcare real estate portfolio with your patient care goals. We streamline your network of facilities to boost operational efficiency, improve patient accessibility, and support the evolving needs of your healthcare delivery models.`,
  services: [
    {
      title: "Portfolio Planning & Optimization Strategy",
      details: `Align your healthcare real estate portfolio with your patient care objectives. 
        We optimize your network of facilities to enhance operational efficiency, improve 
        patient access, and support your evolving healthcare delivery models.`,
    },
    {
      title: "Site Evaluation & Viability Assessment",
      details: `Discover optimal locations for your healthcare facilities. Using a data-driven approach, we assess key factors like demographics, accessibility, and community health needs, ensuring your expansion plans are both impactful and efficient.`,
    },
    {
      title: "Transaction Management",
      details: `Optimize the value of your healthcare real estate transactions. We offer impartial guidance and expert negotiation support, securing flexible agreements that respond to the evolving healthcare landscape while prioritizing your operational requirements.`,
    },
  ],
};

// const defaultarticles = [
//   {
//     category: "INSIGHTS",
//     date: "1 MAY",
//     title: "Understanding Office Lease Flexibility",
//     description: "Lease flexibility has emerged as a strategic imperative for businesses, enabling adaptable office spaces, cost-effective management, and agile navigation of economic uncertainties...",
//     image: "https://cdn.prod.website-files.com/6650989eef5b5b85680d0708/66f46d896034e3d81c4276a0_Retail_Pop-Up.avif",
//   },
//   {
//     category: "NEWS",
//     date: "6 MAR",
//     title: "Transforming Office Spaces for the Hybrid Workforce",
//     description: "Businesses can strategically transform their office spaces to support hybrid work models by implementing flexible designs, integrating advanced technology, fostering a collaborative...",
//     image: "https://cdn.prod.website-files.com/6650989eef5b5b85680d0708/66f469239887a765a5d033a2_Office_image%2015.avif",
//   },
//   {
//     category: "INSIGHTS",
//     date: "15 FEB",
//     title: "Trends Shaping Modern Workplaces",
//     description: "Optimizing office environments through innovative design and technology to promote collaboration and productivity in the evolving workplace.",
//     image: "https://cdn.prod.website-files.com/6650989eef5b5b85680d0708/66f46a8a2c7b9e5fa7c29d2f_Retail_image_4.avif",
//   }
// ]
const HealthCare = () => {
  return (
    <>
      <IndustriesHeroComp
        Textinjsx={healthcareData.Textinjsx}
        img={healthcareData.img}
      />

      <SecondComponent
        leftText={healthcareData.leftText}
        rightText={healthcareData.description}
      />

      <ThirdCompnent
        heading="End-to-End Healthcare Real Estate Solutions"
        sections={healthcareData.services}
      />

      {/* <RelatedArticle articles={defaultarticles}/> */}
    </>
  );
};

export default HealthCare;
