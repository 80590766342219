import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import "react-multi-carousel/lib/styles.css";
import "./Style.css";
import Carousel from "react-multi-carousel";
import DropdownIcon from "../GlobalComponent/DropdownIcon/DropdownIcon";
import AlokBajpai from "../Images/AlokBajpai.jpeg";
import TapanKumarPanigrahi from "../Images/TapanKumarPanigrahi.png";

const defaultarticles = [
  {
    category: "Leadership",
    name: "Tapan Kumar Panigrahi",
    position: "CEO and MD",
    location: "Bangalore,India",
    image:
      "https://mailstone.s3.us-east-1.amazonaws.com/Images/TapanKumarPanigrahi.png",
  },
  {
    category: "Leadership",
    name: "Alok Bajpai",
    position: "Director",
    location: "Bangalore,India",
    image:
      "https://mailstone.s3.us-east-1.amazonaws.com/Images/AlokBajpai.jpeg",
  },
  // {
  //   category: "Leadership",
  //   name: "Clarence Seah",
  //   position: "Deputy Managing Director",
  //   location: "Bangalore,India",
  //   image:
  //     "https://cdn.prod.website-files.com/6650989eef5b5b85680d0708/66be6acda7611bb6b76eb790_Clarence%20Seah.avif",
  // },
  // {
  //   category: "Leadership",
  //   name: "Simon Ward, MRICS",
  //   position: "EMEA Regional Lead",
  //   location: "London",
  //   image:
  //     "https://cdn.prod.website-files.com/6650989eef5b5b85680d0708/66e0936b0404b80c27758e08_Simon%20Ward.avif",
  // },
  // {
  //   category: "Leadership",
  //   name: "Muthu Ganesh",
  //   position: "Senior Vice President – Finance",
  //   location: "Bangalore",
  //   image:
  //     "https://cdn.prod.website-files.com/6650989eef5b5b85680d0708/66bebbadce846a42bbd5b30e_Ganesh.avif",
  // },
];

const GlobalLeaderShip = ({ articles = defaultarticles }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  const [isHovered, setIsHovered] = useState(false);

  const CustomButtonGroup = ({ next, previous }) => {
    const [isHovered, setIsHovered] = useState(false);
    return (
      <div className="GlobalLeaderShip-buttons position-absolute bottom-0 start-0 d-flex gap-2 mb-4">
        <button
          className="GlobalLeaderShip-button"
          onClick={previous}
          aria-label="Previous slide"
          onMouseEnter={() => setIsHovered("previous")}
          onMouseLeave={() => setIsHovered(false)}
        >
          <DropdownIcon v3={true} isHovered={isHovered === "previous"} />
        </button>
        <button
          className="GlobalLeaderShip-button"
          onClick={next}
          aria-label="Next slide"
          onMouseEnter={() => setIsHovered("next")}
          onMouseLeave={() => setIsHovered(false)}
        >
          <DropdownIcon v2={true} isHovered={isHovered === "next"} />
        </button>
      </div>
    );
  };

  return (
    <Container className="GlobalLeaderShip-container py-5 position-relative">
      <div className="d-flex justify-content-between align-items-start mb-5">
        <h2 className="GlobalLeaderShip-title">Leadership</h2>
        {/* <button onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)} className="border-0 px-4 py-2 rounded-3  d-flex justify-content-center align-items-center">
          View All People <DropdownIcon isHovered={isHovered} v2={true}/>
        </button> */}
      </div>
      <Carousel
        responsive={responsive}
        infinite={true}
        customButtonGroup={<CustomButtonGroup />}
        arrows={false}
        renderButtonGroupOutside={true}
        className="GlobalLeaderShip-wrapper"
      >
        {articles.map((article, index) => (
          <div key={index} className="GlobalLeaderShip-item px-3 mb-5">
            <img
              src={article.image}
              alt={article.name}
              //   using blend remove background
              style={{
                mixBlendMode: "multiply",
                backgroundImage: `url(https://cdn.prod.website-files.com/664e075a24cd16808e4650fc/665dd29d8cdcde998ec12ac7_people_bg-pattern-light.svg)`,
                objectFit: "cover",
                backgroundColor: "#EFF0F2",
                aspectRatio: "2.3/3",
              }}
              className="GlobalLeaderShip-image w-100 object-fit-cover h-100 rounded-3 p-1 rounded-3"
            />
            <div className="my-3">
              <h4
                style={{
                  fontSize: "18px",
                  color: "rgb(10, 21, 30)",
                  lineHeight: ".9",
                }}
              >
                {article.name}
              </h4>
              <div style={{ fontSize: "16px", color: "rgb(10, 21, 30)" }}>
                {article.position}
              </div>
              <div style={{ fontSize: "16px", color: "rgb(158,166,167)" }}>
                {article.location}
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </Container>
  );
};

export default GlobalLeaderShip;
