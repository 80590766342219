import React from "react";
import "./Footer.css";
import { Col, Container, Image, Row } from "react-bootstrap";
import { IoLogoFacebook } from "react-icons/io5";
import { FaLinkedin } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io";
import { BsInstagram } from "react-icons/bs";
import ReadyToTalk from "../ReadyToTalk/ReadyToTalk";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div>
      <div className="my-4">
        <ReadyToTalk />
      </div>
      <div
        style={{ backgroundColor: "#0D3333" }}
        className="card-page-fluid234"
      >
        <Row>
          <Col md={2} xs={5}>
            <Row>
              <Col className="logo2class3">
                <Link className="text-decoration-none" to="/">
                  <Image
                    style={{
                      scale: "1.1",
                    }}
                    src="https://res.cloudinary.com/dtjjk0vwy/image/upload/v1719407224/sg5tdi8uycp97izaed1g.png"
                    className="logo2"
                  />
                </Link>
              </Col>
            </Row>
          </Col>
          {/* <Col md={2} xs={6} className="companyclass d-flex justify-content-center align-items-center flex-column"> */}
          <Col md={2} xs={6} className="companyclassss">
            {/* <Col className="class12345">
              <a target="_blank" rel="noreferrer" className="text-decoration-none" href="https://www.facebook.com/jbsinteriors">
                <IoLogoFacebook color="white" size={23} /></a>
            </Col>
            <Col className="class12345">
              <a target="_blank" rel="noreferrer" className="text-decoration-none" href="https://www.linkedin.com/company/jbs-interiors/">
                <FaLinkedin color="white" size={23} /></a>
            </Col> */}
            <Col className="class12345">
              <a
                target="_blank"
                rel="noreferrer"
                className="text-decoration-none"
                href="https://www.youtube.com/@JBSInteriors"
              >
                <IoLogoYoutube color="white" size={23} />
              </a>
            </Col>
            <Col className="class12345">
              <a
                target="_blank"
                rel="noreferrer"
                className="text-decoration-none"
                href="https://www.instagram.com/mailstonepltd2022?igsh=MXZteDQ0emlld2xjag%3D%3D"
              >
                <BsInstagram color="white" size={23} />
              </a>
            </Col>
          </Col>
          <Col md={2} xs={6} className="companyclass">
            <Row className="class12345">
              <Col className="logo2class1">
                <Link
                  className="text-decoration-none text-white"
                  to={"/careers"}
                >
                  Careers
                </Link>
              </Col>
            </Row>
            <Row className="class12345">
              <Col className="logo2class1">
                <Link to={"/"} className="text-decoration-none text-white">
                  Home
                </Link>
              </Col>
            </Row>
            <Row className="class12345">
              <Col className="logo2class1">
                <Link
                  to={"/services"}
                  className="text-decoration-none text-white"
                >
                  Services
                </Link>
              </Col>
            </Row>
            <Row className="class12345">
              <Col className="logo2class1">
                <Link
                  to={"/contactus"}
                  className="text-decoration-none text-white"
                >
                  Contact Us
                </Link>
              </Col>
            </Row>
            {/* <Row className="class12345">
              <Col className="logo2class1">About us</Col>
            </Row> */}
          </Col>
          <Col md={5} xs={12} className="companyclass23">
            <Row>
              <Col className="logo2class1">Information</Col>
            </Row>
            <Row>
              <Col className="logo2class1">
                2Q34+7XC, Nagarathnamma Layout, Belathur, Kadugodi, Bengaluru,
                Karnataka 560067
              </Col>
            </Row>
            <Row>
              <Col className="logo2class1">Phone: (+91) 8050141583</Col>
            </Row>
            <Row>
              <Col className="logo2class1">Information</Col>
            </Row>
            <Row>
              <Col className="logo2class1">
                Email: newbusiness@mailstone.co.in
              </Col>
            </Row>
          </Col>
        </Row>
        <hr className="hr-tag-hrr" />
        <Container>
          <Row
            style={{
              textAlign: "center",
              alignItems: "center",
            }}
          >
            <Col className="codekartpvt">
              @2024 Mailstone Pvt.Ltd. Designed By Codekart Solutions Pvt.Ltd.
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
