import React from "react";
import ServicesHeroSection from "../Component/ServicesHeroSection/ServicesHeroSection";
import AccordationComp from "../Component/AccordationComp/AccordationComp";
import SuccessStories from "../Component/SussessStories/SuccessStories";
import RelatedArticle from "../Component/RelatedArticle/RelatedArticle";
import Portfolio from "../../Images/Portfolio.jpeg"
import autocarindia from "../../Images/autocarindia.jpeg"
import img from "../../Images/img.png"
import GEHealthcare from "../../Images/GEHealthcare.jpeg"
const PortfolioSolutions = () => {
  const AccordationData = [
    {
      header: "Portfolio Planning & Enhancement",
      text: "We align your entire commercial interiors portfolio with your business goals, crafting a unified strategy to enhance performance across all locations. Our holistic approach addresses every aspect of your interior design footprint to boost efficiency, minimize costs, and support your long-term objectives.",
      list: [
        "Comprehensive portfolio analysis and strategic planning",
        "Multi-location optimization and rationalization of interior spaces. ",
        "Scenario modeling for business growth or contraction. ",
        "Cost reduction strategies across the entire commercial interiors portfolio. ",
        "Integration of global and local market insights for strategicdecision-making. ",
      ],
    },
    {
      header: "Unified Transaction Management",
      text: "Our comprehensive approach to transactions ensures that every deal, from site selection to lease negotiation, aligns with your broader commercial interior strategy. We combine global expertise with local market insights to secure terms that advance your business goals across your entire portfolio.",
      list: [
        "Strategic lease analysis and portfolio planning.",
        "Seamless coordination of multi-location transactions and relocations.",
        "Targeted acquisition and disposition strategies for interior spaces.",
        "Efficient lease administration and critical date management for all locations.",
      ],
    },
    {
      header: "Workplace Portfolio Strategy and Execution",
      text: "We seamlessly combine workspace planning, design, and project management across your entire portfolio. Our holistic approach ensures a unified workplace strategy while allowing for local customization, creating environments that boost productivity and reflect your brand identity at every location.- Portfolio-wide workplace strategy formulation ",
      list: [
        "Standardized design guidelines with flexibility for local adaptation.",
        "Coordinated multi-site project planning and execution.",
        "Change management strategies for company-wide initiatives",
      ],
    },
    {
      header: "Portfolio Performance Analytics",
      text: "By utilizing our Vportal platform, we offer a unified solution for all your portfolio and project management requirements. Our data-driven insights support real-time decision-making, highlight trends across your portfolio, and reveal opportunities for optimization and cost savings.",
      list: [
        "Centralized data management and reporting via Vportal.",
        "Customized KPI tracking and benchmarking across the entire portfolio.",
        "Predictive analytics to enhance portfolio performance.",
        "Real-time project tracking and progress reporting across the portfolio.",
        "Integration of financial, operational, and occupancy data for comprehensive analysis.",
      ],
    },
  ];
  const defaultstories = [
    {
      title:
        "Ensuring Budget Discipline Amid Evolving Conditions for a Global IT Leader",
      sector: "SOFTWARE & TECHNOLOGY",
      year: "2024",
      image: img,
    },
    {
      title: "Crafting a Luxurious Experience: Interior Design for the Mercedes-Benz Showroom in Bangalore",
      sector: "COMMERCIAL & RETAIL",
      year: "2023",
      image: autocarindia
      // image:
      //   "https://cdn.prod.website-files.com/6650989eef5b5b85680d0708/66daf613dd0f55d13a4ad92d_Mobileye_Case%20Study_thumbnail-p-500.avif",
    },
    {
      title:
        "Advanced Commercial Interior Solutions for GE Healthcare: Specialized Medical Systems with Vibration Rooms and Lead-Lined Installations",
      sector: "HEALTHCARE",
      year: "2023",
      image: GEHealthcare,
    },
  ];

  const accordationText = {
    leftText: "Your complete commercial interior solution ",
    rightText: "From design planning to long-term strategic insights, we tailor our approach to suit your specific commercial interior needs. Consider us an extension of your team, aligning your vision with industry trends to maximize the value and functionality of your workspace.",
  };

  return (
    <div>
      <ServicesHeroSection
        Textinjsx={
          <>
            Portfolio
            <br />
            Solutions
          </>
        }
        // img="https://cdn.prod.website-files.com/664e075a24cd16808e4650fc/66c7ac2a52ac84ca0be3c77b_Portfolio%20SolutionsBW.avif"
        // img={Portfolio}
        img={`https://mailstone.s3.us-east-1.amazonaws.com/Images/Portfolio.jpeg`}
      />
      <AccordationComp
        leftText={accordationText.leftText}
        rightText={accordationText.rightText}
        AccordationContent={AccordationData}
      />
      <SuccessStories stories={defaultstories} />
      <RelatedArticle />
    </div>
  );
};

export default PortfolioSolutions;
