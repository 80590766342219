import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { BsArrowLeft } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import "../style.css"
import DropdownIcon from '../../../GlobalComponent/DropdownIcon/DropdownIcon';

const IndustriesHeroComp = ({ Textinjsx, img }) => {
  const [isHovered, setIsHovered] = React.useState(false);
  return (
    <div className="industries-wrapper position-relative overflow-hidden">
      <div className="industries-background"></div>
      <Container className="industries-container py-5">
        <Row className="align-items-center">
          <Col lg={6} className="industries-content py-5">
            <span className="industries-label text-uppercase mb-4 fw-semibold d-block">Services</span>
            <h1 className="industries-title display-1 fw-bold mb-5">
              {Textinjsx || <>Portfolio<br />Solutions</>}
            </h1>
            <Link to="/industries" className="industries-link d-inline-flex align-items-center gap-2">
              {/* <BsArrowLeft className="industries-arrow" /> */}
              <div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} className='d-flex justify-content-start align-items-center'>
                <DropdownIcon v3={true} isHovered={isHovered} />
                <span>View All Industries</span>
              </div>
            </Link>
          </Col>
          <Col lg={6} className="industries-image-col position-relative d-none d-lg-block">
            <div style={{ width: "100%" }} className='ratio ratio-1x1'>
              <Image
                src={img || "https://cdn.prod.website-files.com/664e075a24cd16808e4650fc/66c7ac2a52ac84ca0be3c77b_Portfolio%20SolutionsBW.avif"}
                alt="Portfolio Solutions Meeting"
                className="object-fit-cover mt-auto"
                priority
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default IndustriesHeroComp;
