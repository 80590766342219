import React, { useState } from "react";
import "./HomeLanding.css";
import { Button, Col, Container, Row } from "react-bootstrap";
import HorizontalLine from "../../GlobalComponent/HorizontalLine/HorizontalLine";
import ServiceCard from "../../GlobalComponent/OurServiceCard/ServiceCard";
import worker from "../Assets/worker.png";
import house from "../Assets/house.png";
import light from "../Assets/light (2).png";
import tool from "../Assets/tools.png";
import decoker from "../Assets/decoker.png";
import tree from "../Assets/tree.png";
import { FaXTwitter } from "react-icons/fa6";
import { RiLinkedinBoxFill } from "react-icons/ri";
import { RiInstagramFill } from "react-icons/ri";
import { RiFacebookBoxFill } from "react-icons/ri";
import OptimizedImage from "../../GlobalComponent/OptimizedImage/OptimizedImage";
import DropdownIcon from "../../GlobalComponent/DropdownIcon/DropdownIcon";
import { Link } from "react-router-dom";

const HomeLanding = () => {
  const [IsHovered, SetIsHovered] = useState(false)
  return (
    <div style={{
      minHeight: "100mvh"
    }} className="homeLandingbg position-relative z-1">
      <div className="w-100 h-100 position-absolute" style={{
        zIndex: "2",
        backgroundColor: "rgba(100, 105, 220, .3)"
        // backgroundColor:"rgba(122, 95, 153, 0.6)"
      }}></div>
      <Container className="d-flex position-relative z-3 h-100 justify-content-center align-items-center">
        <div className=" w-100 h-100 homeLandingcontainer">
          <Container fluid>
            <Row>
            <Col md="8" className="">
                <h3 style={{
                  maxWidth: "700px"
                }} className="text-white HomeLanding-heading">We transform commercial interiors to work for you.</h3>
                {/* <h6 className="text-white ">Mailstone delivers complete real estate solutions for ambitious businesses worldwide, turning strategy into tangible outcomes.</h6> */}
              </Col>
              <Col md="4"
                //   style={{
                //   maxWidth: "400px"
                // }} 
                className="ms-auto text-white  d-flex justify-content-end align-items-center">
                <div>
                  <h6
                  style={{
                    fontSize: "25px",
                    marginBottom: "20px"
                  }}
                  >Mailstone provides comprehensive commercial interior solutions for forward-thinking businesses, transforming strategy into real-world results.</h6>
                  <Link className="text-decoration-none" to="/company">
                    <Button onMouseEnter={() => { SetIsHovered(tree) }} onMouseLeave={() => { SetIsHovered(false) }} className="d-flex justify-content-center align-items-center">About Us
                      <DropdownIcon isHovered={IsHovered} v2={true} />
                    </Button>
                  </Link>
                </div>
              </Col>
              
            </Row>
          </Container>
        </div>
        {false && <Col sm={5} xs={12} className="thirdColomn">
          <h1 className="thirdcolomnfirsttext" style={{
            textTransform: "capitalize"
          }}>
            {" "}
            <span className="firsttextspan">We make</span> real estate
            <span className="singlebarline">-</span>
          </h1>
          <h1 className="thirdcolomnsecondtext">

            work for <span className="secondtextspan"> you. </span>
          </h1>
          <p className="thirdcolomnparagraph">
            Mailstone delivers complete real estate solutions for ambitious businesses worldwide, turning strategy into tangible outcomes.
          </p>
          <div>
            {/* <button className="learbbutton">Learn More</button> */}
          </div>
          <div className="dotted-divvv">
            <img
              src="https://res.cloudinary.com/dtjjk0vwy/image/upload/v1720525974/jctkjf5rdlahkxqwwxsf.png"
              className="dottedline"
              loading="lazy"
              alt={``}
            />
          </div>
        </Col>}
      </Container>
      {false && <Container fluid className="h-100">
        <Row className="MainHomeSociallink">
          <Col xs={12} sm={2} className="HomeSociallink">
            <a href="/Twitter">
              <FaXTwitter style={{ cursor: "pointer", zIndex: "4", color: "#0B3F78" }} size={32} />
            </a>
            <a href="jbkn">
              <RiLinkedinBoxFill style={{ cursor: "pointer", zIndex: "4", color: "#0B3F78" }} href="jhvjh" size={32} />
            </a>
            <a href="idbhsjkn">
              <RiInstagramFill style={{ cursor: "pointer", zIndex: "4", color: "#0B3F78" }} href="jhvjh" size={32} />
            </a>
            <a href="ihjnlkmds">
              <RiFacebookBoxFill style={{ cursor: "pointer", zIndex: "4", color: "#0B3F78" }} href="jhvjh" size={32} />
            </a>

          </Col>

          <Col sm={5} xs={12} className="secondColomn">
            {/* <OptimizedImage
            src="https://res.cloudinary.com/dbklt3v4d/image/upload/v1719377129/mailStone/j9bhc8paygknmrql1nuw.png"
            className="MainImage"
            loading="lazy"
            alt={``}
          /> */}
            {/* <OptimizedImage
            src={Building}
            className="MainImage"
            loading="lazy"
            alt={``}
          /> */}
          </Col>
          <Col sm={5} xs={12} className="thirdColomn">
            <h1 className="thirdcolomnfirsttext" style={{
              textTransform: "capitalize"
            }}>
              {" "}
              <span className="firsttextspan">We make</span> real estate
              <span className="singlebarline">-</span>
            </h1>
            <h1 className="thirdcolomnsecondtext">

              work for <span className="secondtextspan"> you. </span>
            </h1>
            <p className="thirdcolomnparagraph">
              Mailstone delivers complete real estate solutions for ambitious businesses worldwide, turning strategy into tangible outcomes.
            </p>
            <div>
              {/* <button className="learbbutton">Learn More</button> */}
            </div>
            {/* <div className="dotted-divvv">
            <img
              src="https://res.cloudinary.com/dtjjk0vwy/image/upload/v1720525974/jctkjf5rdlahkxqwwxsf.png"
              className="dottedline"
              loading="lazy"
              alt={``}
            />
          </div> */}
          </Col>
        </Row>
        <Row className="seconrow">
          <HorizontalLine />
        </Row>
        <Row className="fourthrow">
          <Col sm={3} xs={6}>
            <OptimizedImage
              src="https://res.cloudinary.com/dbklt3v4d/image/upload/v1719384700/mailStone/mvvayepiu70dhtifkae2.png"
              className="secondsectionfirstimage"
              loading="lazy"
              alt={``}
            />
          </Col>
          <Col sm={4} className="tenthcol">
            <p className="textOfSecondSection">
              Tailored Real Estate Solutions for Your Business Success
              At Mailstone, we specialize in transforming your real estate into a strategic asset. With global expertise in corporate real estate, we offer personalized, end-to-end solutions that are designed to support and elevate your business objectives.

              Driving Business Growth with Strategic Real Estate Solutions
              Mailstone brings you customized real estate solutions that drive efficiency, foster growth, and align perfectly with your business needs.          </p>
          </Col>
          <Col sm={5} xs={6}>
            <OptimizedImage
              src="https://res.cloudinary.com/dbklt3v4d/image/upload/v1719384866/mailStone/cujnflednifuewy8ncuw.png"
              className="secondsectionsecondimage"
              loading="lazy"
              alt={``}
            />
          </Col>
          <Col sm={12} xs={12}>
            <p className="showelem">
              At Mailstone, with nearly a century of experience and a legacy
              spanning three generations, we transform houses into homes with our
              expert craftsmanship, timeless design, and comprehensive services,
              including custom-made, handcrafted furniture, elegant interiors
              tailored to your style, and full construction to decor solutions,
              creating spaces that inspire, comfort, and reflect your unique
              personality.
            </p>
          </Col>
        </Row>
        <Row className="seconrow">
          <HorizontalLine />
        </Row>
        <Row>
          <Col className="linebarContainer">
            <div className="linebarcontainer">
              <div className="linebar"></div>
              <h1 className="linebartext">Our Services</h1>
            </div>
          </Col>
        </Row>
        <Row className="">
          <Col sm={4} xs={12}>
            <ServiceCard
              icon={worker}
              name="Construction"
              description="Quality craftsmanship from groundbreaking to project completion. "
            />
          </Col>
          <Col sm={4} xs={12}>
            <ServiceCard
              icon={house}
              name="Interior Design"
              description="From concept to completion, we oversee every detail to bring your vision to life efficiently "
            />
          </Col>
          <Col sm={4} xs={12}>
            <ServiceCard
              icon={tree}
              name="Fabrications Design"
              description="Bespoke pieces crafted to your unique specifications.
"
            />
          </Col>
          <Col sm={4} xs={12}>
            <ServiceCard
              icon={light}
              name="Electrical"
              description="Expert installations and maintenance ensuring safety and efficiency."
            />
          </Col>
          <Col sm={4} xs={12}>
            <ServiceCard
              icon={decoker}
              name="Decor"
              description="Exquisite accents and furnishings elevating ambiance and charm.
"
            />
          </Col>
          <Col sm={4} xs={12}>
            <ServiceCard
              icon={tool}
              name="Plumbing"
              description="Expert solutions for installations, repairs, and maintenance services."
            />
          </Col>
        </Row>
        <Row className="seconrow">
          <HorizontalLine />
        </Row>
      </Container>}
    </div>
  );
};

export default HomeLanding;
