import React from 'react'
import StoriesHeroSec from '../StoriesHeroSec'
import CaseStudySection from '../CaseStudySection'
// import img from "../../../Images/ThoughtFocusstories.jpg"
import img from "../../../Images/img.png"

const ThoughtFocus = () => {
  return (
    <div>
        <StoriesHeroSec Industry={"IT"} img={img} Year={2024} Location={"Bangalore"} Textinjsx={<>Tech Expansion: Steering <br/>Market</>}/>
        <CaseStudySection introduction={"A prominent technology company specializing in advanced driver assistance systems and autonomous driving solutions aimed to grow its presence in the Indian market. To support this expansion, they partnered with Mailstone for site selection, technical evaluations, and project management for their offices, test center, and data center in Bangalore."}
        Solution={"Mailstone developed a comprehensive approach to address the client’s complex needs. We provided essential support during the planning and pre-construction phases, ensuring that technical requirements were aligned with project budgets for various facilities. Our team conducted in-depth technical evaluations of potential sites and managed the bidding process for construction contractors. To meet the power expansion requirements, we collaborated closely with local utilities and the client’s technical team to devise feasible solutions. We also established efficient communication channels to bridge the gap between on-site teams and the operations team, ensuring smooth information exchange and decision-making."}
        Solution2={"Mailstone took responsibility for project and interior design management across all the client’s ventures in Bangalore. We implemented stringent schedule tracking and quality control measures to uphold high workmanship standards across all projects. By leveraging our local expertise and project management skills, Mailstone successfully guided the client through their expansion into the Indian market. Our efforts led to the timely inauguration of their test center in Bangalore, strengthening the client’s presence in this dynamic market and supporting their goal of driving innovation in IT and software services."}
        Challenge={"Our client encountered multiple challenges in expanding into the Indian market, including aligning technical requirements with project budgets for data centers and offices across various locations. The project required significant power expansion and presented communication hurdles with subject matter experts based in Bangalore. Additionally, the company had to ensure that all projects in India adhered to stringent workmanship standards while meeting aggressive timelines."}
        />
    </div>
  )
}

export default ThoughtFocus