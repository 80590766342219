import React, { useState } from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import { BsArrowRight } from 'react-icons/bs'
import './AllServicesBoxComponent.css'
import DropdownIcon from '../../GlobalComponent/DropdownIcon/DropdownIcon';
import { Link } from 'react-router-dom';

export default function AllServicesBoxComponent({
  variant = 'default',
  title = 'Portfolio Solutions',
  description = 'From lease negotiation to big picture thinking and long-term strategic guidance, we personalize our process to meet your needs. Think of us as an extension of your team, matching your objectives with market opportunities to put your real estate to work.',
  services = [
    {title:"Workplace Experience",link:"#"},
    {title:"Workplace Experience",link:"#"},
    {title:"Workplace Experience",link:"#"}
  ],
  imageSrc = 'https://cdn.prod.website-files.com/664e075a24cd16808e4650fc/66c7ac2a52ac84ca0be3c77b_Portfolio%20SolutionsBW.avif',
  imageAlt = 'Services Image',
  ctaText = 'Learn More',
  ctaLink = '#'
}) {
  const isAlternative = variant === 'alternative';
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Container className="all-services-box px-0">
      <Row
        className={`align-items-center g-0 ${isAlternative ? 'flex-row-reverse' : ''}`}
      >
        <Col md={6} className={`image-wrapper ${isAlternative ? 'ps-0' : 'px-0'}`}>
          <div className="position-relative w-100 p-3">
            <Image
              src={imageSrc}
              alt={imageAlt}
              className="w-100 rounded-4 object-fit-cover"
            />
          </div>
        </Col>

        <Col md={6} className={`content-wrapper ${isAlternative ? '' : ''}`}>
          <div className="text-wrapper">
            <h1 className={`title ${isAlternative ? 'display-2' : 'display-2'} mb-4`}>
              {title}
            </h1>
            <p className={`description ${isAlternative ? 'mb-5' : ' mb-5'}`}>
              {description}
            </p>
          </div>
          {services.length > 0 && (
            <div className="services mb-5">
              <Row className="g-4">
                {services.map((service, index) => (
                  <Col md={6} key={service.id || index}>
                    <h2
                      href={service.link}
                      className={`AllServicesBoxComponentservice-link text-decoration-none text-start ${
                        isAlternative ? 'alternative-style' : ''
                      }`}
                      aria-label={service.title}
                    >
                      {service.title}
                    </h2>
                  </Col>
                ))}
              </Row>
            </div>
          )}
          {ctaText && ctaLink && (
            <Link
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              to={ctaLink}
              className={`cta btn py-3 rounded-2 ${
                isAlternative ? 'btn-dark rounded-0' : 'btn-dark rounded-1'
              } d-inline-flex align-items-center gap-2`}
              aria-label={ctaText}
            >
              {ctaText}
              <DropdownIcon isHovered={isHovered } v2={true} />
            </Link>
          )}
        </Col>
      </Row>
    </Container>
  );
}
