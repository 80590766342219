import React from "react";
import "./IndustryAllService.css";
import { Container, Row, Col } from "react-bootstrap";
import AllServiceCard from "../../GlobalComponent/AllServiceCard/AllServiceCard";

// Define the industries data
const industries = [
  // { name: "Automotive" },
  // { name: "Education" },
  // { name: "Financial Services" },
  // { name: "Food & Beverage" },
  // { name: "Health & Wellness" },
  { name: "Healthcare",link:"/industries/healthcare" },
  { name: "Industrial",link:"/industries/industrial" },
  // { name: "Law Firms" },
  // { name: "Life Sciences" },
  { name: "AMC",link:"amc" },
  { name: "Office",link:"/industries/office" },
  { name: "Education",link:"/industries/education" },
  { name: "Software & Technology",link:"/industries/software-technology" },
  // { name: "Retail" },
  // { name: "Software & Technology" },
  // { name: "Transportation & Logistics" },
];

const IndustryAllService = () => {
  return (
    <Container fluid className="p-5">
      {/* Title Section */}
      <Row className="industryallservice-container">
        <Col>
          <h1 className="industryallservice-title">Industries</h1>
        </Col>
      </Row>

      {/* Industries Grid Section */}
      <Row md={4} sm={6}>
        {industries.map((industry, index) => (
          <Col key={index} className="mt-4">
            <AllServiceCard link={industry.link} name={industry.name} />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default IndustryAllService;
