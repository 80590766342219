import React from 'react'
import StoriesHeroSec from '../StoriesHeroSec'
import CaseStudySection from '../CaseStudySection'

const MedicalResearchNetworkUSHeadquartersRelocation = () => {
  return (
    <div>
        <StoriesHeroSec/>
        <CaseStudySection/>
    </div>
  )
}

export default MedicalResearchNetworkUSHeadquartersRelocation