import React from 'react'
import IndustriesHeroComp from '../Component/IndustriesHeroComp/IndustriesHeroComp'
import SecondComponent from '../Component/SecondComponent/SecondComponent'
import ThirdCompnent from '../Component/ThirdCompnent/ThirdCompnent'
import RelatedArticle from '../../Services/Component/RelatedArticle/RelatedArticle'
import img from "../../Images/industries.jpg"

const industrialData = {
  Textinjsx: "Industrial",
  industriesView: ["View All Industries", "A forklift driver in a warehouse"],
  img: img,
  leftText:
    "Engineer your facilities for peak performance and future readiness",
  description: "Your industrial spaces are the backbone of your business, powering productivity and fostering innovation. We collaborate with businesses of all sizes to deliver real estate solutions designed to boost efficiency, adaptability, and growth. Whether it’s cutting-edge production facilities or advanced distribution centers, we’ll help you build an industrial portfolio that optimizes operations, promotes sustainability, and prepares your business to thrive in the era of Industry 4.0 and beyond.",
};
const sections = [
  {
    title: "Portfolio Strategy & Optimization",
    details: `Unlock the full potential of your industrial real estate portfolio. We align your facility network with your business goals, enhancing space efficiency, minimizing costs, and adapting to your evolving production and distribution needs across multiple locations.`,
    link:"/services/portfoliosolutions"
  },
  {
    title: "Design & Project Management",
    details: `We create spaces where business thrives through human-centered design, energy efficient infrastructure solutions, and smart integrated technology. Our workplaces blend functionality with modern design, creating environments that increase employee happiness. `,
        link:"/services/design-project-services"
  },
  {
    title: "Transaction Management",
    details: `Confidently manage complex industrial real estate transactions. Whether expanding your distribution network or streamlining operations, we offer market expertise and robust negotiation support to secure flexible agreements that prioritize your business objectives.`,
    link:"/services/transaction"
  },
];

export const Industrial = () => {
    return (
      <>
        <IndustriesHeroComp
          Textinjsx={industrialData.Textinjsx}
          img={industrialData.img}
        />
        <SecondComponent
          leftText={industrialData.leftText}
          rightText={industrialData.description}
        />
        <ThirdCompnent 
        heading={"Customized Industrial Real Estate Solutions Aligned with Your Business Objectives"}
        sections={sections} />                                                                                                                                                                                                                       
        <RelatedArticle />
      </>
    );
}
