import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import './ContactUs.css'
import ContactUsForm from './ContactUsForm'
import Getimg from "../Images/get-touch.jpg"

const ContactUs = () => {
    return (
        <div
            style={{
                backgroundColor: '#0A151E',
                // backgroundImage: 'url("https://cdn.prod.website-files.com/664e075a24cd16808e4650fc/665ddb0df29ed73dfe1f6759_branded-svg_light.svg")',
                // backgroundSize: 'contain',
                // backgroundPosition: 'left bottom',
                // backgroundRepeat: 'no-repeat',
                minHeight: '100vh'
            }}
            className='text-white position-relative '
        >
            <img style={{
                opacity: ".2",
                transform: 'rotate(90deg)'
            }} className='position-absolute bottom-0 start-0' src="https://cdn.prod.website-files.com/664e075a24cd16808e4650fc/665ddb0df29ed73dfe1f6759_branded-svg_light.svg" alt=""></img>
            <Container className=" py-5 mt-5 z-3">
                <div className="ContactUs-content">
                    <h1 className="ContactUs-title mb-4">Contact us</h1>

                    <div className="ContactUs-card">
                        <div className="ContactUs-image-wrapper mb-4">
                            <Image
                                src={Getimg}
                                alt="Team members collaborating in office"
                                className="ContactUs-image w-100 h-100 object-fit-cover rounded-3"
                                width={2000}
                                height={700}
                                priority
                            />
                        </div>
                        <div className="ContactUss2-container py-5">
                            <Row className="ContactUss2-row justify-content-between gx-5">
                                <Col md={6} className="ContactUss2-title-col">
                                    <h1 className="ContactUss2-title mb-4">
                                        Get in touch
                                    </h1>
                                    <h6 className='ContactUssdiscription2-title'>
                                        Need to ask us a question, access our brand resources, or just want to learn more about our company culture?
                                    </h6>
                                    <h6 className='ContactUssdiscription2-title mt-4'>Fill out the form and our team will respond as soon as possible.</h6>
                                </Col>
                                <Col md={6} className="ContactUss2-description-col">
                                    <ContactUsForm />
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                <Container className='ContactUs-card-container'>
                    <div className='p-4 rounded-3 contactusCard1'>
                        <h6 className='text-white mb-4'>
                            NEW BUSINESS
                        </h6>
                        <h5 className='mt-5'>
                        newbusiness@mailstone.co.in
                        </h5>
                    </div>
                    <div className='p-4 rounded-3 contactusCard2'>
                        <h6 className='text-white mb-4'>
                            CAREERS
                        </h6>
                        <h5 className='mt-5'>
                            careers@mailstone.co.in
                        </h5>
                    </div>
                    <div className='p-4 rounded-3 contactusCard2'>
                    <h6 className='text-white mb-4'>
                            GENERAL INQUIRIES
                        </h6>
                        <h5 className='mt-5'>
                        generalenquiry@mailstone.co.in
                        </h5>
                    </div>
                </Container>
            </Container>
        </div>
    )
}

export default ContactUs