import React from "react";
import { FaArrowDownLong } from "react-icons/fa6";
import "./DropdownIcon.css";

const DropdownIcon = ({ isHovered, v2, v3 }) => {
  return (
    <div
      style={v2 ? { transform: "rotate(-90deg)" } : v3 ? { transform: "rotate(90deg)" } : {}}
      className={`naaaavdropdownicon mx-2 p-1 rounded-circle aspect-square ${isHovered ? "hovered" : ""
        }`}
    >
      <FaArrowDownLong color="white" size={13} className="arrow arrow-white" />
      <FaArrowDownLong color="black" size={13} className="arrow arrow-black" />
    </div>
  );
};

export default DropdownIcon;
