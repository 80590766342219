import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./Style.css";
import DropdownIcon from "../GlobalComponent/DropdownIcon/DropdownIcon";
import company from "../Images/company.png";

const CompanySectionone = () => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div
      style={{
        backgroundColor: "#06151E",
        color: "white",
        padding: "50px 0",
      }}
    >
      <Container fluid>
        <Row className="align-items-center flex flex-md-row flex-column-reverse">
          <Col
            style={{
              aspectRatio: "1/1",
            }}
            md={6}
            className="CompanySectiononeimg-section position-relative"
          >
            <div className="w-100">
              <img
                className="CompanySectiononeimg"
                src={company}
                alt="Map"
                style={{ width: "100%", borderRadius: "15px" }}
              />
            </div>
          </Col>

          <Col md={6} className="CompanySectiononecontent-section2 px-3">
            <h2 className="CompanySectiononeheading">
              Global Vision,
              <br /> Local Excellence.
            </h2>
            <p className="CompanySectiononetext">
              Mailstone blends global expertise with local insight to offer
              innovative corporate commercial interiors solutions. Our
              international network of professionals crafts customized
              strategies that turn commercial interiors challenges into
              strategic business opportunities. Whether serving emerging
              startups or Fortune 500 companies, we transform individual
              projects into long-term partnerships.{" "}
            </p>
            <Row className=" VestianSection-border">
              <Col md={4} className="text-left">
                <div className=" p-3 border-top border-2">
                  <h2 className="CompanySectiononeheading2">3</h2>
                  <p className="CompanySectiononetext">Offices</p>
                </div>
              </Col>
              <Col md={4} className="text-left">
                <div className=" p-3 border-top border-2">
                  <h2 className="CompanySectiononeheading2">200</h2>
                  <p className="CompanySectiononetext">Professionals</p>
                </div>
              </Col>
              <Col md={4} className="text-left">
                <div className=" p-3 border-top border-2">
                  <h2 className="CompanySectiononeheading2">650+</h2>
                  <p className="CompanySectiononetext">Completed Projects</p>
                </div>
              </Col>
            </Row>
            <div className="mt-4">
              <a
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
                href="https://www.google.com/maps/dir/13.0088656,77.7566516/jbs+interiors/@13.0060287,77.7542019,17z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3bae0fbe30b32b01:0x61705129601b444e!2m2!1d77.757343!2d13.0031818?entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D "
              >
                <Button
                  onMouseEnter={() => {
                    setIsHovered(true);
                  }}
                  onMouseLeave={() => {
                    setIsHovered(false);
                  }}
                  variant="primary"
                  className="rounded-1 d-flex justify-content-center align-items-center"
                  size="lg"
                >
                  Find an Office Near You{" "}
                  <DropdownIcon isHovered={isHovered} v2={true} />
                </Button>
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CompanySectionone;
