// File: ThirdCompnent.jsx
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import DropdownIcon from "../../../GlobalComponent/DropdownIcon/DropdownIcon";
const defaultsections = [
  {
    title: "Portfolio Strategy & Optimization",
    details: `Unlock the full potential of your industrial real estate portfolio. We align your facility network with your business goals, enhancing space efficiency, minimizing costs, and adapting to your evolving production and distribution needs across multiple locations.`,
    link:"/services/portfoliosolutions"
  },
  {
    title: "Design & Project Management",
    details: `We create spaces where business thrives through human-centered design, energy efficient infrastructure solutions, and smart integrated technology. Our workplaces blend functionality with modern design, creating environments that increase employee happiness. `,
        link:"/services/design-project-services"
  },
  {
    title: "Transaction Management",
    details: `Confidently manage complex industrial real estate transactions. Whether expanding your distribution network or streamlining operations, we offer market expertise and robust negotiation support to secure flexible agreements that prioritize your business objectives.`,
    link:"/services/transaction"
  },
];

const ThirdCompnent = ({ sections = defaultsections, heading }) => {
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <Container className="my-5">
      {/* Main Heading */}
      <Row className="mb-4">
        <Col md={6}>
          <h1 className="display-4">
            {heading ||
              "Industrial Real Estate Solutions Tailored to Your Business Goals"}
          </h1>
        </Col>
        <Col md={6}>
          <Container fluid>
            {sections.map((section, index) => (
              <Row key={index} className="mb-5">
                <Col md={8}>
                  <h3>{section.title}</h3>
                  <p>{section.details}</p>
                </Col>
                <Col md={4} className="d-flex align-items-center">
                  <Link
                    to={section?.link}

                    className="text-decoration-none fw-semibold text-dark"
                  >
                    <div onMouseEnter={() => setIsHovered(index)}
                      onMouseLeave={() => setIsHovered(false)} className="d-flex align-items-center justify-content-end">
                      Learn more{" "}
                      <DropdownIcon v2={true} isHovered={isHovered === index} />
                    </div>
                  </Link>
                </Col>
              </Row>
            ))}
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default ThirdCompnent;
