import React, { useState } from "react";
import "./ContactInfo.css";
import { Alert, Col, Container, Row } from "react-bootstrap";
import { FaTwitter, FaInstagram, FaFacebook } from "react-icons/fa";
import HorizontalLine from "../../GlobalComponent/HorizontalLine/HorizontalLine";
import { GoArrowDownRight } from "react-icons/go";
import BiggerCard from "../../GlobalComponent/CardsContainer/BiggerCard";
import SmallerCard from "../../GlobalComponent/CardsContainer/SmallerCard";
import SecondSectionBigCard from "../../GlobalComponent/CardsContainer/SecondSectionBigCard";
import SecondSectionSmall from "../../GlobalComponent/CardsContainer/SecondSectionSmall";
import CoWorkingSpace from "../../Images/CoWorkingSpace.jpg";
import emailjs from "@emailjs/browser";
import imggg from "../../Images/annual-maintenance-service.jpg";
import imgggggg from "../../Images/homeimg.webp";

const ContactInfo = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const [error, setError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    // Simple validation for required fields
    if (!formData.fullName || !formData.email || !formData.phoneNumber) {
      // if (!formData.email ){
      //   console.log("formData.email no there")
      // }
      // if (!formData.fullName){
      //   console.log("formData.fullName no there")
      // }
      // if (!formData.phoneNumber){
      //   console.log("formData.phoneNumber no there")
      // }
      setError("Full Name, Email, and Phone Number are required.");
      return;
    }

    // Clear error message if validation passes
    setError("");

    const emailData = {
      first_name: formData.fullName,
      last_name: "",
      company_name: "",
      subject: "",
      message: formData.message,
      email: formData.email,
      phone_number: formData.phoneNumber,
    };

    emailjs
      .send(
        "service_czxn5ob", // Replace with your EmailJS service ID
        "template_rz7m7gh", // Replace with your EmailJS template ID
        emailData, // Form data to send
        "UlTgZeorI1kM_Fy3B" // Replace with your EmailJS public key
      )
      .then(
        (response) => {
          alert("Email sent successfully!");
          setFormData({
            fullName: "",
            email: "",
            phoneNumber: "",
            message: "",
          });
        },
        (error) => {
          console.error("Email sending failed:", error);
        }
      );
  };

  return (
    <Container>
      <Row className="contactInforow gx-4">
        <Col sm={6} xs={12} className="contactInfoFirstCol pe-sm-3">
          <h1 className="infomationfirsttext">Get in touch for </h1>
          <h1 className="infomationsecondtext">Stunning interiors.</h1>
          <div className="colorcontainer">
            <div className="contactinfoform">
              <h1 className="contactinfoformheader">Contact Information</h1>
              <p className="contactinfoformparagraph">
                Say something to start a live chat!
              </p>
              <form onSubmit={handleSubmit}>
                {error && <Alert variant="danger">{error}</Alert>}
                <div>
                  <p className="contactinfoheaders">Full Name</p>
                  <input
                    type="text"
                    className="contactinfoinput"
                    value={formData.fullName}
                    onChange={(e) =>
                      setFormData({ ...formData, fullName: e.target.value })
                    }
                  />
                </div>
                <div>
                  <p className="contactinfoheaders">Email</p>
                  <input
                    type="text"
                    className="contactinfoinput"
                    value={formData.email}
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                  />
                </div>
                <div>
                  <p className="contactinfoheaders">Phone Number</p>
                  <input
                    type="text"
                    className="contactinfoinput"
                    value={formData.phoneNumber}
                    onChange={(e) =>
                      setFormData({ ...formData, phoneNumber: e.target.value })
                    }
                  />
                </div>
                <div>
                  <p className="contactinfoheaders">Write your Message</p>
                  <input
                    type="text"
                    className="contactinfoinput text-black"
                    value={formData.message}
                    onChange={(e) =>
                      setFormData({ ...formData, message: e.target.value })
                    }
                  />
                </div>
                <div className="contactinfobuttoncontainer">
                  <button className="contactinfobutton" type="submit">
                    Send Message
                  </button>
                </div>
              </form>
            </div>
            <div className="circlecontainer">
              <div className="circleicons">
                <div className="socialicon">
                  <FaTwitter />
                </div>
                <div className="socialicon">
                  <FaInstagram />
                </div>
                <div className="socialicon">
                  <FaFacebook />
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col sm={6} xs={12} className="circlesideimage ps-sm-3"></Col>
      </Row>
      <Row>
        <HorizontalLine />
      </Row>
      <Row>
        <Col sm={12} xs={12}>
          <ul>
            <li className="contactinfoli">Projects</li>
          </ul>
        </Col>
        <Col className="projectfirsttext projectcontain" sm={12} xs={12}>
          <h1 className="projecttexts123">UNLEASHING</h1>
        </Col>
        <Col className="projectsecondtext projectcontain" sm={12} xs={12}>
          <h1 className="projecttexts"> THE</h1>
        </Col>
        <Col className="projectthirdtext projectcontain" sm={12} xs={12}>
          <h1 className="projecttexts"> POTENTIAL IN </h1>
        </Col>
        <Col className="projectfourthtext projectcontain" sm={12} xs={12}>
          <h1 className="projecticon">
            <GoArrowDownRight />
          </h1>
          <h1 className="projecttexts">EVERY SPACE</h1>
        </Col>
      </Row>
      <Row>
        <HorizontalLine />
      </Row>
      <Row className="imageContainerTop">
        <BiggerCard
          headername="Co-Working Space"
          buttonname="Commercials"
          imgurl={CoWorkingSpace}
          contained="An innovative co-working space that offers a dynamic environment for startups and freelancers. This project highlights our commitment to creating versatile, inspiring spaces that cater to the needs of modern professionals."
        />
        <SmallerCard
          headername="Annual Maintenance Contract"
          buttonname="   AMC   "
          imgurl={
            "https://mailstone.s3.us-east-1.amazonaws.com/Images/annual-maintenance-service.jpg"
          }
          contained="At Mailstone, we offer comprehensive Annual Maintenance Contracts (AMC) to ensure the smooth operation and longevity of your facilities and equipment. Our AMC services provide proactive maintenance, regular inspections, and prompt repairs, minimizing downtime and maximizing efficiency. We understand the importance of maintaining the optimal performance of your assets, and our experienced team is dedicated to delivering cost-effective, reliable solutions tailored to your specific needs."
        />
      </Row>

      <Row className="mt-4">
        <SecondSectionSmall
          headername="Elegant Living Rooms"
          buttonname="Residentials"
          imgurl="https://res.cloudinary.com/dbklt3v4d/image/upload/v1719470739/mailStone/c8svyl19zmain5liuylz.png"
          contained="We design elegant living rooms that reflect the homeowner's personality and style. This project exemplifies our attention to detail and commitment to creating comfortable, stylish living spaces."
        />
        <SecondSectionBigCard
          headername="Innovative Office Spaces"
          buttonname="Commercials"
          imgurl="https://res.cloudinary.com/dbklt3v4d/image/upload/v1719470950/mailStone/vkhvuruxjkarpgfimfys.png"
          contained="Our innovative office spaces are designed to enhance productivity and foster creativity. This project highlights our expertise in creating functional, aesthetically pleasing work environments that cater to modern business needs."
        />
      </Row>

      <Row className="mt-4">
        <BiggerCard
          headername="UPVC Windows and Sliding Doors"
          buttonname="Fabrications"
          imgurl="https://res.cloudinary.com/dbklt3v4d/image/upload/v1719472426/mailStone/uufxyllebtjzeiwyzoma.png"
          contained="Specializing in UPVC windows and sliding doors, we utilize cutting-edge technology and state-of-the-art facilities to ensure precise manufacturing and timely delivery, enhancing the aesthetic appeal and functionality of any space."
        />
        <SmallerCard
          headername="HVAC Systems"
          buttonname="Electricals"
          imgurl={
            "https://mailstone.s3.us-east-1.amazonaws.com/Images/homeimg.webp"
          }
          contained="At Mailstone, we install HVAC systems designed for optimal climate control. Our services ensure efficient, reliable, and safe environments, providing comfort and energy efficiency for both residential and commercial spaces."
        />
      </Row>
    </Container>
  );
};

export default ContactInfo;
