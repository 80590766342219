import React from "react";
import ServicesHeroSection from "../Component/ServicesHeroSection/ServicesHeroSection";
import AccordationComp from "../Component/AccordationComp/AccordationComp";
import SuccessStories from "../Component/SussessStories/SuccessStories";
import RelatedArticle from "../Component/RelatedArticle/RelatedArticle";

const Transaction = () => {
  const AccordationData = [
    // {
    //   header: "Lease Advisory & Restructuring",
    //   text: "Optimize your lease agreements for better business outcomes. We analyze your current leases and market conditions to negotiate terms that support your operational and financial goals.",
    //   list: [
    //     "Comprehensive lease analysis and benchmarking.",
    //     "Rent review negotiations.",
    //     "Lease restructuring and renegotiation.",
    //     "Lease exit and renewal strategies.",
    //     "Portfolio optimization for leased properties.",
    //   ],
    // },
    {
      header: "Location Analysis & Viability Assessment",
      text: "Discover the ideal location for your business with our expert team, blending in-depth local market insight and advanced analytics to identify and assess sites that align with your unique requirements and growth strategy, whether you're leasing or purchasing.",
      list: [
        "In-depth demographic and market analysis focused on your business needs.",
        "Location modeling and scoring tailored to corporate goals.",
        "On-site tours and comprehensive evaluations.",
        "Feasibility assessments for potential business location",
      ],
    },
    {
      header: "Financial Markets & Investment Solutions",
      text: "Enhance the value of your commercial interior portfolio. Whether you’re redesigning, relocating, or optimizing your interior spaces, our team offers strategic guidance and execution support to help you achieve your business goals.",
      list: [
        "Acquisition and disposition services for commercial interior spaces. ",
        "Sale-leaseback solutions for interior portfolio optimization. ",
        " Financing options for property acquisitions.",
        "Financing options for interior design and space acquisitions. ",
        "Portfolio management and project support for commercial interiors.",
        "Market analysis and space valuation for business-owned interiors.",
      ],
    },
    // {
    //   header: "Lease Administration",
    //   text: "Leveraging our Vportal platform, we provide a single source of truth for all your portfolio and project management needs. Our data-driven insights enable real-time decision-making, identify trends across your portfolio, and uncover opportunities for improvement and cost savings.",
    //   list: [
    //     "Centralized data management and reporting through Vportal.",
    //     "Custom KPI tracking and benchmarking across the portfolio.",
    //     "Predictive analytics for portfolio performance optimization.",
    //     "Real-time project tracking and portfolio-wide progress reporting.",
    //     "Integration of financial, operational, and occupancy data for comprehensive analysis.",
    //   ],
    // },
  ];

  const accordationText = {
    leftText: "Each transaction is a chance for growth.",
    rightText:"Maximize the potential of your commercial interiors with expert guidance and strong negotiation support, ensuring solutions that align with your business priorities. We analyze in-depth market data, identify hidden opportunities, and secure flexible arrangements that focus on your needs.",
  };

  return (
    <div>
      <ServicesHeroSection
        Textinjsx={
          <>
            Transaction
            <br />
            Management
          </>
        }
        img="https://www.yardicorom.com/wp-content/uploads/sites/73/2024/03/2299807799.jpg?resize=1024,683"
      />
      <AccordationComp
        leftText={accordationText.leftText}
        rightText={accordationText.rightText}
        AccordationContent={AccordationData}
      />
      {/* <SuccessStories /> */}
      <RelatedArticle />
    </div>
  );
};

export default Transaction;
