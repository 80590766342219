import React from "react";
import { Container } from "react-bootstrap";
import "./AllServices.css";
import AllServicesBoxComponent from "./AllServicesBoxComponent";
import portfolio from "../../Images/Portfolio.jpeg";
import designprojectservices from "../../Images/design-project-services.jpg";
import facility from "../../Images/facility-manager-guide.png";

const AllServices = () => {
  const servicesData = [
    {
      title: "Portfolio Solutions",
      description:
        "From design planning to long-term strategic insights, we tailor our approach to suit your specific commercial interior needs. Consider us an extension of your team, aligning your vision with industry trends to maximize the value and functionality of your workspace.",
      services: [
        { title: "Portfolio Strategy & Optimization", link: "#" },
        { title: "Integrated Transaction Management", link: "#" },
        { title: "Workspace Portfolio Planning & Delivery", link: "#" },
        { title: "Portfolio Performance Analytics", link: "#" },
      ],
      imageSrc:
        "https://mailstone.s3.us-east-1.amazonaws.com/Images/Portfolio.jpeg",
      imageAlt: "Services Image",
      btnText: "Learn More",
      btnLink: "/services/portfoliosolutions",
    },
    // {
    //   title: "Advisory Services",
    //   description:
    //     "From market analysis to risk management, we approach every point on your real estate journey with strategic expertise to maximize your business potential. Our advisory services include: comprehensive market research, locally-informed and accurate valuations, incentive and labor market analysis, sustainable urban solutions, and data-driven investment guidance for a more robust portfolio.",
    //   services: [
    //     { title: "Market Research & Assessment", link: "#" },
    //     { title: "Urban Infrastructure Planning", link: "#" },
    //     { title: "Valuation Advisory", link: "#" },
    //     { title: "Investment Advisory", link: "#" },
    //     { title: "Incentives & Labor Analytics", link: "#" },
    //   ],
    //   imageSrc:
    //     "https://cdn.prod.website-files.com/664e075a24cd16808e4650fc/66c7ac1491fcb5d16aee54bb_Advisory%20Services_BW-p-800.avif",
    //   imageAlt: "Advisory Services Image",
    //   btnText: "Learn More",
    //   btnLink: "#",
    // },
    {
      title: "Transaction Management",
      description:
        "Maximize the potential of your commercial interiors with expert guidance and strong negotiation support, ensuring solutions that align with your business priorities. We analyze in-depth market data, identify hidden opportunities, and secure flexible arrangements that focus on your needs.",
      services: [
        { title: "Lease Advisory & Restructuring", link: "#" },
        { title: "Capital Markets", link: "#" },
        { title: "Site Selection & Feasibility", link: "#" },
        { title: "Lease Administration", link: "#" },
      ],
      imageSrc:
        "https://www.yardicorom.com/wp-content/uploads/sites/73/2024/03/2299807799.jpg?resize=1024,683",
      imageAlt: "Transaction Management Image",
      btnText: "Learn More",
      btnLink: "/services/transaction",
    },
    {
      title: "Design & Project Management",
      description:
        "We create spaces where business thrives through human-centered design, energy efficient infrastructure solutions, and smart integrated technology. Our workplaces blend functionality with modern design, creating environments that increase employee happiness. Plus, our global footprint means we can help you put down roots anywhere.",
      services: [
        { title: "Design", link: "#" },
        { title: "Project Management", link: "#" },
        { title: "Workplace Strategy", link: "#" },
        { title: "Cost Consultancy", link: "#" },
        { title: "Occupancy Planning", link: "#" },
      ],
      imageSrc: designprojectservices,
      imageAlt: "Design & Project Management Image",
      btnText: "Learn More",
      btnLink: "/services/design-project-services",
    },
    {
      title: "Facility Management",
      description:
        "Optimize your facilities with our comprehensive management solutions. We take care of daily operations, maintenance, and sustainability efforts, allowing you to focus on your core business.",
      services: [
        { title: "Workplace Experience", link: "#" },
        { title: "Strategic Sourcing", link: "#" },
        { title: "Engineering & Technical Services", link: "#" },
        { title: "Sustainability Services", link: "#" },
      ],
      imageSrc: facility,
      imageAlt: "Facility Management Image",
      btnText: "Learn More",
      btnLink: "/services/facility",
    },
  ];

  return (
    <Container>
      <div
        style={{ minHeight: "100vh" }}
        className="d-flex justify-content-center align-items-start flex-column"
      >
        <div className="d-flex justify-content-center align-items-start">
          <div
            className="m-auto"
            style={{
              height: "11px",
              width: "11px",
              backgroundColor: "rgb(32, 77, 236)",
            }}
          ></div>
          <h6 className="m-auto ms-2">Services</h6>
        </div>
        <h3 className="AllServices-heading">
          Tailored to <br /> Transform
        </h3>
      </div>
      {servicesData.map((service, index) => (
        <AllServicesBoxComponent
          key={index}
          title={service.title}
          description={service.description}
          services={service.services}
          imageSrc={service.imageSrc}
          imageAlt={service.imageAlt}
          btnText={service.btnText}
          ctaLink={service.btnLink}
          variant={index % 2 === 1 ? "alternative" : undefined}
        />
      ))}
    </Container>
  );
};

export default AllServices;
