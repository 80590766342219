'use client'

import { useState } from 'react'
import { Container, Row, Col, Pagination } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import DropdownIcon from '../GlobalComponent/DropdownIcon/DropdownIcon';

const jobsData = [
  {
    id: 1,
    title: "Senior Architect",
    department: "Senior Architect",
    location: "Bangalore",
  },
  {
    id: 2,
    title: "Field Marketing Executive",
    department: "Field Marketing Executive",
    location: "Bangalore",
  },
  {
    id: 3,
    title: "Project Management Consultant",
    department: "Project Management Consultant",
    location: "Bangalore",
  }
];


const itemsPerPage = 5

const JobItem = ({ title, department, location, index }) => {
  const [isHovered, setIsHovered] = useState(false)
  return (
    <Row className="CareersSectionTwo-job-item mb-3 py-3 border-bottom border-secondary align-items-center">
      <Col xs={12} md={8}>
        <h3 className="CareersSectionTwo-job-title h5 my-1">{title}</h3>
        <div className="CareersSectionTwo-job-details my-2">
          {location}
        </div>
      </Col>
      <Col xs={12} md={4} className="text-md-end mt-2 mt-md-0">
        <a className='text-decoration-none text-white' href='mailto:careers@mailstone.co.in'>
          <button onMouseEnter={() => setIsHovered(index)} onMouseLeave={() => setIsHovered(null)} variant="outline-light" size="sm" className="CareersSectionTwo-apply-btn">
            <div className='d-flex justify-content-between align-items-center'>
              Apply Now <DropdownIcon isHovered={isHovered === index} v2={true} />
            </div>
          </button>
        </a>
      </Col>
    </Row>
  )
}

const PaginationComponent = ({ totalPages, activePage, onPageChange }) => (
  <div className='d-flex justify-content-between w-100 mt-5 align-items-center'>
    {/* if current page is 1 then disable prev button invisible */}
    <button onClick={() => {
      if (activePage === 1) {
        return
      }
      onPageChange(activePage - 1)
    }} style={{ visibility: activePage === 1 ? 'hidden' : 'visible' }} className=' CareersSectionTwo-pagination-prev-button'>Prev</button>
    <Pagination className="CareersSectionTwo-pagination-list m-auto">
      {Array.from({ length: totalPages }).map((_, index) => (
        <Pagination.Item
          key={index + 1}
          active={index + 1 === activePage}
          onClick={() => onPageChange(index + 1)}
          className="CareersSectionTwo-pagination-item"
        >
          {index + 1}
        </Pagination.Item>
      ))}
    </Pagination>
    <button onClick={() => {
      if (activePage === totalPages) {
        return
      }
      onPageChange(activePage + 1)
    }} style={{ visibility: activePage === totalPages ? 'hidden' : 'visible' }} className='CareersSectionTwo-pagination-next-button'>Next</button>
  </div>
)

export default function CareersSectionTwo() {
  const [activePage, setActivePage] = useState(1)
  const totalPages = Math.ceil(jobsData.length / itemsPerPage)
  const startIndex = (activePage - 1) * itemsPerPage
  const currentJobs = jobsData.slice(startIndex, startIndex + itemsPerPage)

  return (
    <div className="CareersSectionTwo-wrapper bg-dark text-white py-5">
      <Container>
        <h2 className="CareersSectionTwo-heading mb-5">Open Roles</h2>

        <div className="CareersSectionTwo-jobs">
          {currentJobs.map((job, index) => (
            <JobItem
              key={job.id}
              title={job.title}
              department={job.department}
              location={job.location}
              index={index}
            />
          ))}
        </div>

        {!totalPages === 1 && <div className="CareersSectionTwo-pagination d-flex justify-content-center mt-4">
          <PaginationComponent
            totalPages={totalPages}
            activePage={activePage}
            onPageChange={setActivePage}
          />
        </div>
        }
      </Container>
    </div>
  )
}
