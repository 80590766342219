import React from "react";
import ServicesHeroSection from "../Component/ServicesHeroSection/ServicesHeroSection";
import AccordationComp from "../Component/AccordationComp/AccordationComp";
import SuccessStories from "../Component/SussessStories/SuccessStories";
import RelatedArticle from "../Component/RelatedArticle/RelatedArticle";
import facility from "../../Images/facility-manager-guide.png"

const Facility = () => {
  const AccordationData = [
    {
      header: "Facilities Maintenance and Engineering Solutions",
      text: "Maximize the performance of your business properties. Our engineering and technical services guarantee that your facilities run efficiently, safely, and sustainably, supporting your core operations.",
      list: [
        "Preventive and on-demand maintenance for corporate facilities.",
        "Energy optimization and management for business environments.  ",
        "Building systems assessments and enhancements for company properties. ",
        "Compliance and safety oversight in corporate spaces.",
        "Integration of technology and smart building solutions for modern businesses.",
      ],
    },
    {
      header: "Employee Experience In Work-Space",
      text: "Create a workspace where your employees can excel. We focus on improving the workplace experience to boost productivity, engagement, and retention within your corporate environment.",
      list: [
        "Reception and concierge services for corporate offices. ",
        "Space and relocation management to accommodate evolving business requirements. ",
        "Coordination of amenities and services within company facilities. ",
        "Mailstone employee satisfaction surveys and initiatives for ongoing improvements.",
        "Workplace technology support to ensure seamless business operations. ",
      ],
    },
    {
      header: "Strategic Procurement",
      text: "Expert strategic sourcing services. We assist in selecting and managing vendors, negotiating contracts, and implementing cost-saving strategies across your commercial interior portfolio.",
      list: [
        "Vendor selection and management for business facility services.  ",
        "Contract negotiation and administration for commercial interiors.  ",
        "Streamlined procurement processes for interior-related requirements. ",
        "Supply chain management for commercial interior services. ",
        "Cost optimization strategies for business interior operations. ",
      ],
    },
    {
      header: "Sustainability Solutions  ",
      text: "Prepare your business for the future with our comprehensive sustainability and green building services. We guide you through the ever-changing landscape of environmental regulations, social responsibility, and corporate governance. Our team collaborates with you to adopt sustainable practices that reduce your environmental footprint while boosting your reputation and profitability.",
      list: [
        "Development and implementation of sustainability strategies for commercial interiors.  ",
        "Green building certifications and energy efficiency solutions for interior spaces. ",
        "Waste reduction, recycling initiatives, and sustainable sourcing for interior materials. ",
        "Sustainability reporting, including carbon footprint assessments for commercial interiors. ",
        "Climate risk evaluation and resilience planning for your interior portfolio. ",
        "Social impact evaluations and community engagement strategies for your workspace. ",
      ],
    },
  ];

  const accordationText = {
    leftText: "Let Us Handle The Rest",
    rightText:
      "We want you to concentrate on growing your business, not on managing your workspace. Our team takes care of everyday operations such as maintenance, security, and space management, using data-driven insights to identify opportunities for efficiency improvements and cost savings. Additionally, we collaborate on long-term strategic sourcing, vendor management, and sustainability initiatives, including LEED certifications and the integration of green technologies.",
  };

  return (
    <div>
      <ServicesHeroSection
        Textinjsx={
          <>
            Facility
            <br />
            Management
          </>
        }
        img={facility}
      />
      <AccordationComp
        leftText={accordationText.leftText}
        rightText={accordationText.rightText}
        AccordationContent={AccordationData}
      />
      <SuccessStories />
      <RelatedArticle />
    </div>
  );
};

export default Facility;
