import React, { useState } from "react";
import { Form, Button, Row, Col, Alert } from "react-bootstrap";
import Select from "react-select";
import emailjs from "@emailjs/browser";

const ContactUsForm = () => {
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        companyName: "",
        phoneNumber: "",
        subject: "",
        message: "",
    });

    const [error, setError] = useState(""); 

    const options = [
        { value: "general", label: "General Inquiry" },
        { value: "support", label: "Support" },
        { value: "sales", label: "Sales" },
        { value: "feedback", label: "Feedback" },
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSelectChange = (selectedOption) => {
        setFormData((prevData) => ({ ...prevData, subject: selectedOption?.label || "" }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Simple validation for required fields
        if (!formData.firstName || !formData.lastName || !formData.email || !formData.phoneNumber) {
            setError("First Name, Last Name, Email, and Phone Number are required.");
            return;
        }

        setError("");

        const emailData = {
            first_name:formData.firstName,
            last_name:formData.lastName,
            company_name:formData.companyName,
            subject: formData.subject, 
            message: formData.message,
            email: formData.email,
            phone_number: formData.phoneNumber
          };
        // Send email via EmailJS
        emailjs
            .send(
                "service_czxn5ob", // Replace with your EmailJS service ID
                "template_rz7m7gh", // Replace with your EmailJS template ID
                emailData, // Form data to send
                "UlTgZeorI1kM_Fy3B" // Replace with your EmailJS public key
            )
            .then(
                (response) => {
                    alert("Email sent successfully!");
                    setFormData({
                        firstName: "",
                        lastName: "",
                        email: "",
                        companyName: "",
                        phoneNumber: "",
                        subject: "",
                        message: "",
                    }); // Clear form data after submission
                },
                (error) => {
                    console.error("Email sending failed:", error);
                }
            );
    };

    return (
        <Form onSubmit={handleSubmit}>
            {error && <Alert variant="danger">{error}</Alert>} {/* Display error message */}
            <Row>
                <Col md={6} className="mb-3">
                    <Form.Group controlId="firstName">
                        <Form.Control
                            type="text"
                            placeholder="First Name"
                            className="ContactUsForm-input"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                    <Form.Group controlId="lastName">
                        <Form.Control
                            type="text"
                            placeholder="Last Name"
                            className="ContactUsForm-input"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Form.Group controlId="email" className="mb-3">
                <Form.Control
                    type="email"
                    placeholder="Your Email"
                    className="ContactUsForm-input"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group controlId="companyName" className="mb-3">
                <Form.Control
                    type="text"
                    placeholder="Company Name"
                    className="ContactUsForm-input"
                    name="companyName"
                    value={formData.companyName}
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group controlId="phoneNumber" className="mb-3">
                <Form.Control
                    type="tel"
                    placeholder="Phone Number (Optional)"
                    className="ContactUsForm-input"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group controlId="subject" className="mb-3">
                <Form.Label style={{ width: "content", color: "#888686" }}>
                    What do you need?
                </Form.Label>
                <Select
                    options={options}
                    placeholder="Pick a subject..."
                    isSearchable
                    className="ContactUsForm-select w-100 text-white"
                    classNamePrefix="ContactUsForm"
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            backgroundColor: "transparent",
                            border: "none",
                            borderBottom: "2px solid #ccc",
                            boxShadow: "none",
                            borderRadius: "0",
                            color: "888686",
                        }),
                        placeholder: (provided) => ({
                            ...provided,
                            color: "#888686",
                        }),
                        dropdownIndicator: (provided) => ({
                            ...provided,
                            color: "#888686",
                        }),
                        indicatorSeparator: (provided) => ({
                            ...provided,
                            display: "none",
                        }),
                        menu: (provided) => ({
                            ...provided,
                            backgroundColor: "#333",
                            borderRadius: "0.25rem",
                            color: "white",
                        }),
                        menuList: (provided) => ({
                            ...provided,
                            padding: "0",
                        }),
                        option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isSelected ? "#555" : "#333",
                            color: "white",
                            border: "1px solid #fff",
                            cursor: "pointer",
                            ":hover": {
                                backgroundColor: "#444",
                            },
                        }),
                    }}
                    onChange={handleSelectChange}
                />
            </Form.Group>
            <Form.Group controlId="message" className="mb-3">
                <Form.Control
                    as="textarea"
                    rows={4}
                    placeholder="Message"
                    className="ContactUsForm-input"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Text className="text-white mb-3 d-block">
                Submitting this form confirms your express consent to receive electronic communications. These communications consist of educational materials, opportunities for online events, and corporate information. You may withdraw your consent by unsubscribing at any time.
            </Form.Text>
            <Button variant="primary" type="submit" className="p-3">
                Send Message
            </Button>
        </Form>
    );
};

export default ContactUsForm;
