import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import "./Style.css"

const StoriesHeroSec = ({ Textinjsx, img, Industry,Year,Location }) => {
    return (
        <div style={{
            backgroundColor: "#0D3333"
        }} className="StoriesHeroSec-wrapper position-relative">
            <div className="StoriesHeroSec-background"></div>
            <Container className="StoriesHeroSec-container py-5">
                <Row className="align-items-center">
                    <Col lg={6} className="StoriesHeroSec-content py-5">
                        <span className="StoriesHeroSec-label text-uppercase mb-4 fw-semibold d-block">Success stories</span>
                        <h1 className="StoriesHeroSec-title display-1 fw-bold mb-5">
                            {Textinjsx || <>Portfolio<br />Solutions</>}
                        </h1>
                        <Row>
                            <Col md="4" className='text-white text-start d-flex'>
                                <div style={{
                                    textAlign:"left"
                                }} className='text-start'>
                                    <h6 className='text-dark-emphasis'>Industry</h6>
                                    <h5 className='fw-bold'>{Industry || "Healthcare"}</h5>
                                </div>
                            </Col>
                            <Col md="4" className='text-white d-flex'>
                                <div style={{
                                    textAlign:"left"
                                }} className='text-start'>
                                    <h6 className='text-dark-emphasis'>Year</h6>
                                    <h5 className='fw-bold'>{Year || 2024}</h5>
                                </div>
                            </Col>
                            <Col md="4" className='text-white text-start d-flex'>
                                <div style={{
                                    textAlign:"left"
                                }} className='text-start'>
                                    <h6 className='text-dark-emphasis'>Location</h6>
                                    <h5 className='fw-bold'>{Location || "Bhubaneswar"}</h5>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={6} className="StoriesHeroSec-image-col position-relative">
                        <div style={{ width: "100%" }} className='ratio ratio-1x1'>
                            <Image
                                src={img || "https://cdn.prod.website-files.com/664e075a24cd16808e4650fc/66c7ac2a52ac84ca0be3c77b_Portfolio%20SolutionsBW.avif"}
                                alt="Portfolio Solutions Meeting"
                                className="object-fit-cover mt-auto"
                                priority
                            />
                        </div>
                        
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default StoriesHeroSec;
