import React from "react";
import "./UpperFooter.css";
import { Container, Row, Col } from "react-bootstrap";

const UpperFooter = () => {
  return (
    <Container fluid className="upperfooterContainer  mt-5">
      <Row>
        {/* <Col sm={12} xs={12} className="upperfooterImage">
          <img
            src="https://res.cloudinary.com/dbklt3v4d/image/upload/v1719474389/mailStone/mq7gotjks6d01yqsksc9.png"
            alt=""
            className="footerimage"
          />
        </Col> */}
        <Col sm={12} xs={12} className="footersecondcol p-0">
          <div style={{ backgroundColor: "#0D3333" }}  className="footerfirsttext ">
            <h1 className="firstfootertext mt-auto">MEET OUR</h1>
          </div>
          <div className="footersecontextt">
            <h1 className="secondfootertext">HAPPY CLIENTS</h1>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default UpperFooter;
