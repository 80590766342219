import React from 'react'
import StoriesHeroSec from '../StoriesHeroSec'
import CaseStudySection from '../CaseStudySection'
import img from "../../../Images/healthcare-g.jpg"

const GEHealthcare = () => {
  return (
    <div>
        <StoriesHeroSec img={img} Year={2023} Location={"Bangalore"} Textinjsx={<>Innovating Healthcare,<br/> Improving Lives</>}/>
        <CaseStudySection introduction={"GE Health, a healthcare system specializing in therapeutic treatments for rare diseases, collaborated with Mailstone to implement an ambitious expansion strategy. The objective was to establish more than five new locations across India within a 10-month period. With a diverse real estate portfolio that includes office, laboratory, warehouse, and pharmacy spaces, GE required a strategic approach to effectively optimize expansion, capitalize on market opportunities, and control costs."}
        Challenge={"GE Health encountered the challenging task of quickly expanding its healthcare facilities while optimizing costs and securing strategic locations. The company needed to identify sites with the appropriate infrastructure to support its specialized services, manage capital expenses using landlord allowances and existing building systems, and ensure proximity to hospitals, medical centers, and key demographics. This had to be accomplished within a demanding 10-month timeline across various markets."}
        Solution={"Mailstone crafted a customized real estate strategy to support GE Health’s ambitious expansion objectives. Our approach involved identifying the best locations nationwide and narrowing down 3-5 options per market from an initial 8 that aligned with GE’s specific requirements. We issued competitive RFPs, negotiated favorable rent terms and concessions, and utilized our VPortal for streamlined data management."}
        Solution2={" Throughout the process, we provided full project management services to ensure seamless execution. This strategy allowed GE Health to open 5 new sites in 2023, including doubling the size of key hubs in Bangalore at reduced rates. By capitalizing on market trends and optimizing costs, we significantly lowered capital expenditures while facilitating expansion. As a result, we positioned GE Health for sustained growth and enhanced operational efficiency in their specialized healthcare services across India."}
        />
    </div>
  )
}

export default GEHealthcare