import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import "./Style.css"

const CaseStudySection = ({introduction,Challenge,Solution,Solution2,Solution3}) => {
    return (
        <Container className='py-5'>
            {/* Introduction */}
            <Row className="casestudy-intro mb-4">
                <Col>
                    <h2 className="casestudy-intro-title">Introduction</h2>
                    <p className="casestudy-intro-text">
                        {introduction || "Our client faced a significant time constraint, needing to complete both site construction and data center infrastructure in approximately 10 weeks. This aggressive timeline, coupled with the project's scale and complexity, demanded meticulous planning and execution. Additionally, the client’s needs evolved during the project, requiring flexibility to accommodate an increase in employee capacity from 1,700 to 2,000."}
                    </p>
                </Col>
            </Row>

            {false && <Container fluid className="py-4 d-flex flex-column flex-md-row">
                {/* Left Section */}
                <div className="d-flex flex-column flex-md-row">
                    {/* Top Box */}
                    <div
                        className="p-3 p-3"
                    >
                        <div className='p-4 text-white h-100'
                        style={{
                            backgroundColor: "#6B224F",
                            borderRadius: "8px",
                            textAlign: "center",
                        }}
                        >
                            <h2>145k SF</h2>
                            <p>expansion across four buildings</p>
                        </div>
                    </div>
                    {/* Bottom Box */}
                    <div
                        className="p-3"
                        style={{
                            borderRadius: "8px",
                            textAlign: "center",
                        }}
                    >
                        <div className='h-100 p-4 bg-light'>

                            <h2>10 weeks</h2>
                            <p>to complete site construction and data center infrastructure</p>
                        </div>
                    </div>
                </div>

                {/* Right Section */}
                <div
                    className=" flex-column p-3 flex-md-row"
                    style={{
                        borderRadius: "8px",
                        textAlign: "center",
                    }}
                >
                    <div className='h-100 p-4 bg-light'>

                        <h2>Delivered project under budget and on time</h2>
                    </div>
                </div>
            </Container>}

            {/* Challenge */}
            <Row className="casestudy-challenge mb-4">
                <Col>
                    <h2 className="casestudy-challenge-title">Challenge</h2>
                    <p className="casestudy-challenge-text">
                        {Challenge ||"Our client faced a significant time constraint, needing to complete both site construction and data center infrastructure in approximately 10 weeks. This aggressive timeline, coupled with the project's scale and complexity, demanded meticulous planning and execution. Additionally, the client’s needs evolved during the project, requiring flexibility to accommodate an increase in employee capacity from 1,700 to 2,000."}
                    </p>
                </Col>
            </Row>

            {/* Solution */}
            <Row className="casestudy-solution">
                <Col>
                    <h2 className="casestudy-solution-title">Solution</h2>
                    <p className="casestudy-solution-text">
                        {Solution||"Vestian implemented a comprehensive strategy to meet the client's needs within the tight timeline. We applied our understanding of the client’s requirements, gained during the bid process, to develop an efficient project plan. Our team maintained a hands-on approach throughout the assignment, with Vestian principals actively involved and team members working extended hours when necessary to ensure project success. We managed the expansion of the four-building campus, including the large data center, adapting our plans to accommodate the increased employee capacity. Our planning and execution allowed us to complete the site construction and data center infrastructure within the 10-week timeline."}
                    </p>
                    <p className="casestudy-solution-text">
                        {Solution2}
                    </p>
                    <p className="casestudy-solution-text">
                        {Solution3}
                    </p>
                </Col>
            </Row>
        </Container>
    );
};

export default CaseStudySection;
