'use client'

import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import careerimg from "../Images/career.png"

export default function CareersSectionone() {
  return (
    <Container className=" py-5 mt-4">
      <div className="CareersSection-content">
        <h1 className="CareersSection-title mb-4">Careers</h1>

        <div className="CareersSection-card">
          <div className="CareersSection-image-wrapper mb-4">
            <Image
              src={careerimg}
              alt="Team members collaborating in office"
              className="CareersSection-image w-100 h-100 object-fit-cover rounded-3"
              width={2000}
              height={400}
              priority
            />
          </div>
          <div className="PortfolioSolutions-container py-5">
            <Row className="PortfolioSolutions-row justify-content-between gx-5">
              <Col md={6} className="PortfolioSolutions-title-col">
                <h1 className="PortfolioSolutions-title display-4 fw-bold mb-4" style={{ fontSize: '3.5rem' }}>
                  {"Put Your Expertise to Work with Us"}
                </h1>
              </Col>
              <Col md={6} className="PortfolioSolutions-description-col">
                <p className="PortfolioSolutions-description lead mb-5">
                  {" At Mailstone, we're building a global team of CRE innovators who are passionate about delivering results for ambitious businesses. We offer a collaborative environment where your ideas shape the future of corporate real estate. Our commitment to transparency and client success drives everything we do. If you're ready to tackle complex challenges, create innovative solutions, and grow your career on an international stage, join us. Together, we'll redefine what's possible in corporate real estate, one client at a time."}
                </p>
                {/* <div>
                View All Open Roles
                </div> */}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Container>
  )
}

