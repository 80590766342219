import React from "react";
import StoriesHeroSec from "../StoriesHeroSec";
import CaseStudySection from "../CaseStudySection";
import img from "../../../Images/autocarindia.jpeg";

const MercedesBenz = () => {
  return (
    <div>
      <StoriesHeroSec
        img={
          "https://mailstone.s3.us-east-1.amazonaws.com/Images/autocarindia.jpeg"
        }
        Year={2024}
        Industry={"Automotive"}
        Location={"Bangalore"}
        Textinjsx={
          <>
            Navigating Challenges in
            <br /> Interior Build-Out Project
          </>
        }
      />
      <CaseStudySection
        introduction={
          "A leading brand showroom encountered several challenges in executing a major interior build-out project in Bangalore. The project was hindered by a tight timeline with significant penalties for delays, along with input from multiple business unit leaders, each with differing expectations and specifications."
        }
        Challenge={
          "Our client was tasked with completing a 4,000-square-foot interior build-out within a tight deadline, facing potential penalties if the existing space was not reinstated on time. Additionally, we had to manage input from multiple stakeholders, each with different project expectations and specifications."
        }
        Solution={
          "At Mailstone, we take pride in our honesty and integrity, even when it means rejecting non-standard contract terms we deem unrealistic or unfair. In this instance, our approach proved successful. After initially turning down the project due to problematic contract terms, the client ultimately selected us, recognizing the value of our transparency and expertise."
        }
        Solution2={
          "Once on board, we prioritized clear communication with all stakeholders to swiftly align on project specifications. Our team implemented careful schedule planning and maintained a strong onsite presence throughout the build-out. As a result, we completed the project ahead of schedule, enabling the client to reinstate their existing facility without facing penalties. This early completion led to significant cost savings for the client."
        }
        Solution3={
          "Our performance on this project impressed the client so much that they awarded us another upcoming project. This experience highlights how our commitment to honesty and realistic project management fosters successful outcomes and builds long-term client relationships."
        }
      />
    </div>
  );
};

export default MercedesBenz;
