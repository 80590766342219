import React from 'react'
import { Accordion, Col, Container, Row } from 'react-bootstrap'
import "../style.css"
const DefaultAccordationContent = [{
  header: "Portfolio Strategy & Optimization",
  text: "We align your entire real estate portfolio with your business objectives, creating a cohesive strategy that optimizes performance across all your locations. Our integrated approach considers every aspect of your real estate footprint to drive efficiency, reduce costs, and support your long-term business goals.",
  list: ["Comprehensive portfolio analysis and strategic planning.",
    "Multi-location optimization and rationalization.",
    "Scenario modeling for business growth or contraction.",
    " Cost reduction strategies across the entire portfolio.",
    "Integration of global and local market insights for strategic decision-making."
  ]
}, {
  header: "Integrated Transaction Management",
  text: "Our portfolio-wide approach to transactions ensures that every deal, from site selection to lease negotiation, aligns with your overarching real estate strategy. We leverage our global expertise and local market knowledge to secure terms that support your business objectives across your entire portfolio.",
  list: [" Portfolio-wide lease analysis and strategy development.",
    "Multi-location optimization and rationalization.",
    "Strategic acquisition and disposition planning.",
    "Sale-leaseback strategies for portfolio optimization.",
    "Lease administration and critical date management across all locations."
  ]
},
{
  header: "Workspace Portfolio Planning & Delivery",
  text: "We seamlessly integrate workspace planning, design, and project management across your entire portfolio. Our holistic approach ensures consistency in workplace strategy while allowing for local customization, creating environments that enhance productivity and reflect your brand identity across all locations.",
  list: ["Portfolio-wide workplace strategy development.",
    "Standardized design guidelines with local adaptability.",
    "Multi-site project planning and coordination.",
    "Change management strategies for portfolio-wide initiatives.",
    "Integration of construction and relocation management across locations."
  ]
},
{
  header: "Portfolio Performance Analytics",
  text: "By utilizing our Vportal platform, we offer a unified solution for all your portfolio and project management requirements. Our data-driven insights support real-time decision-making, highlight trends across your portfolio, and reveal opportunities for optimization and cost savings.",
  list: [
    "Centralized data management and reporting via Vportal.",
    "Customized KPI tracking and benchmarking across the entire portfolio.",
    "Predictive analytics to enhance portfolio performance.",
    "Real-time project tracking and progress reporting across the portfolio.",
    "Integration of financial, operational, and occupancy data for comprehensive analysis.",
  ],
},
]
const AccordationComp = ({ AccordationContent = DefaultAccordationContent, leftText, rightText }) => {
  return (
    <Container className="PortfolioSolutions-container py-5">
      <Row className="PortfolioSolutions-row justify-content-between gx-5">
        <Col md={6} className="PortfolioSolutions-title-col">
          <h1 className="PortfolioSolutions-title display-4 fw-bold mb-4" style={{ fontSize: '3.5rem' }}>
            {leftText || "Your turn-key real estate solution"}
          </h1>
        </Col>
        <Col md={6} className="PortfolioSolutions-description-col">
          <p className="PortfolioSolutions-description lead mb-5">
            {rightText || " From lease negotiation to big picture thinking and long-term strategic guidance, we personalize our process to meet your needs. Think of us as an extension of your team, matching your objectives with market opportunities to put your real estate to work."}
          </p>
          <Row className="PortfolioSolutions-accordion-row">
            <Col lg={12}>
              <Accordion className="PortfolioSolutions-accordion custom-accordion">
                {AccordationContent.map((item, index) => (
                  <Accordion.Item className="PortfolioSolutions-accordion-item border-0 mb-3" eventKey={index} key={index}>
                    <Accordion.Header className="PortfolioSolutions-accordion-header py-3">{item.header}</Accordion.Header>
                    <Accordion.Body className="PortfolioSolutions-accordion-body">
                      {item.text}
                      <div className='mt-1'>
                        <ul className="PortfolioSolutions-accordion-list">
                          {item.list?.map((listItem, index) => (
                            <li key={index}>- {listItem}</li>
                          ))}
                        </ul>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default AccordationComp