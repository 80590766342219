import React from "react";
import ServicesHeroSection from "../Component/ServicesHeroSection/ServicesHeroSection";
import AccordationComp from "../Component/AccordationComp/AccordationComp";
import SuccessStories from "../Component/SussessStories/SuccessStories";
import RelatedArticle from "../Component/RelatedArticle/RelatedArticle";

const Designproject = () => {
  const AccordationData = [
    {
      header: "Workplace Strategy and Space Planning",
      text: "Design workspaces that boost productivity and employee satisfaction. We craft customized strategies to align your physical environment with your organization's goals and culture, whether in owned or leased spaces.  ",
      list: [
        " Comprehensive workplace needs assessments tailored to your business.  ",
        "Space utilization analyses for corporate settings.",
        "Development of adaptable work strategies.  ",
        "Change management guidance for seamless workplace transitions.  ",
        "Planning for technology integration to Creative Design Solutions",
      ],
    },
    {
      header: "Creative Design Solutions",
      text: "Turn your corporate vision into reality with our cutting-edge design services. We craft functional, visually stunning spaces that embody your brand and align with your business objectives in any location you choose. ",
      list: [
        "Conceptual design creation tailored to corporate needs.",
        " Optimized space planning and layout design.  ",
        "Interior design and finishes selection that reflect your brand identity.",
        "3D visualizations and renderings to preview your business environment.",
        "Sustainable design.",
        "Strategic procurement planning for real estate initiatives.",
        "Financial feasibility analyses for business expansion or relocation.",
      ],
    },
    {
      header: "Cost Management Services",
      text: "Maximize your project's financial efficiency with our expert cost management solutions. We deliver precise budgeting, value-driven engineering, and effective cost control to help you achieve the best return on your corporate real estate investments. ",
      list: [
        "Comprehensive cost estimation and budgeting for business projects.",
        "Value engineering and cost optimization tailored to corporate spaces.   ",
        "Life cycle cost assessments for informed property decisions. ",
      ],
    },
    {
      header: "Project Coordination Services ",
      text: "Ensure the success of your corporate real estate projects with our end-to-end project management expertise. We oversee every phase, from planning to delivery, ensuring efficiency and seamless execution.",
      list: [
        "Development and management of project scope tailored to business needs.  ",
        "Vendor selection and coordination for real estate initiatives.  ",
        "Budget and schedule management for relocations or expansions.  ",
        "Clear and consistent communication with organizatio",
      ],
    },
    {
      header: "Building Management Services",
      text: "Ensure your corporate project is completed on schedule, within budget, and with exceptional quality. Our construction management professionals handle every detail of your business space's build-out.",
      list: [
        "Oversight of contractor selection and management for corporate projects.",
        "Budget management and timeline control for construction or renovations. ",
        "Quality assurance and site inspections for corporate build-outs.",
        "Risk assessment and mitigation throughout the construction process.",
        "Management of sustainability initiatives and LEED certification for business spaces.",
      ],
    },
  ];

  const defaultstories = [
    {
      title:
        "Ensuring Budget Discipline Amid Evolving Conditions for a Global IT Leader",
      sector: "SOFTWARE & TECHNOLOGY",
      year: "2024",
      image: "https://mailstone.s3.us-east-1.amazonaws.com/Images/img.png",
    },
    {
      title:
        "Crafting a Luxurious Experience: Interior Design for the Mercedes-Benz Showroom in Bangalore",
      sector: "COMMERCIAL & RETAIL",
      year: "2023",
      image:
        "https://mailstone.s3.us-east-1.amazonaws.com/Images/autocarindia.jpeg",
    },
    {
      title:
        "Advanced Commercial Interior Solutions for GE Healthcare: Specialized Medical Systems with Vibration Rooms and Lead-Lined Installations",
      sector: "HEALTHCARE",
      year: "2023",
      image:
        "https://mailstone.s3.us-east-1.amazonaws.com/Images/GEHealthcare.jpeg",
    },
  ];

  const accordationText = {
    leftText: "Optimize your workplace for success.",
    rightText:
      "We design spaces where businesses flourish, combining human-centered design, energy-efficient infrastructure, and advanced integrated technology. Our workplaces seamlessly merge functionality with contemporary aesthetics, fostering environments that boost employee well-being. With a global presence, we're ready to help you establish a foundation anywhere in the world.",
  };

  const defaultarticles = [
    {
      category: "INSIGHTS",
      date: "1 MAY",
      title:
        "Together, we're building a brighter future—one product at a time.",
      description:
        "Lease flexibility has emerged as a strategic imperative for businesses, enabling adaptable office spaces, cost-effective management, and agile navigation of economic uncertainties...",
      image: "https://mailstone.s3.us-east-1.amazonaws.com/Images/DSC_0220.JPG",
    },
    {
      category: "NEWS",
      date: "6 MAR",
      title: "Transforming Office Spaces for the Hybrid Workforce",
      description:
        "Businesses can strategically transform their office spaces to support hybrid work models by implementing flexible designs, integrating advanced technology, fostering a collaborative...",
      image: "https://mailstone.s3.us-east-1.amazonaws.com/Images/DSC_0229.JPG",
    },
    {
      category: "INSIGHTS",
      date: "15 FEB",
      title: "Trends Shaping Modern Workplaces",
      description:
        "Optimizing office environments through innovative design and technology to promote collaboration and productivity in the evolving workplace.",
      image: "https://mailstone.s3.us-east-1.amazonaws.com/Images/DSC_0183.JPG",
    },
  ];

  return (
    <div>
      <ServicesHeroSection
        Textinjsx={
          <>
            Design & Project Management
            <br />
            Services
          </>
        }
        img="https://mailstone.s3.us-east-1.amazonaws.com/Images/design-project-services.jpg"
      />
      <AccordationComp
        leftText={accordationText.leftText}
        rightText={accordationText.rightText}
        AccordationContent={AccordationData}
      />
      <SuccessStories stories={defaultstories} />
      <RelatedArticle />
    </div>
  );
};

export default Designproject;
