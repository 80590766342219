// import React, { useCallback, useEffect, useState } from "react";
// import { Navbar, Nav, Container } from "react-bootstrap";
// import { useNavigate, useLocation, Link } from "react-router-dom";
// import { FaArrowRight } from "react-icons/fa6";
// import "./MainNavBar.css";
// import DropdownIcon from "../DropdownIcon/DropdownIcon";

// const services = [
//   { text: "Portfolio Solutions", link: "/services/portfoliosolutions" },
//   { text: "Transaction Management", link: "/services/transaction" },
//   { text: "Facility Management", link: "/services/facility" },
//   { text: "Design & Project Services", link: "/services/design-project-services" },
// ];
// const industries = [
//   { text: "Office", link: "/industries/office" },
//   // { text: "Retail", link: "/industries/retail" },
//   { text: "Healthcare", link: "/industries/healthcare" },
//   { text: "Industrial", link: "/industries/industrial" },
// ];

// const MainNavBar = () => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [expanded, setExpanded] = useState(false);
//   const [showNavBar, setShowNavBar] = useState(true);
//   const [scrollY, setScrollY] = useState(0);
//   const [hoveredDropdown, setHoveredDropdown] = useState(null);
//   const [hoveredItem, setHoveredItem] = useState(null);

//   useEffect(() => {
//     window.scrollTo(0, 0);  // Scrolls to the top of the page
//   }, [location]);
//   const handleMouseEnter = (dropdown) => {
//     setHoveredDropdown(dropdown);
//     setHoveredItem(dropdown);
//   };

//   const handleMouseLeave = () => {
//     setHoveredDropdown(null);
//     setHoveredItem(null);
//   };

//   useEffect(() => {
//     setHoveredDropdown(null);
//   }, [expanded]);

//   const handleNavClick = (e, path) => {
//     e.preventDefault();
//     setExpanded(false);
//     navigate(path);
//   };

//   const controlScroll = useCallback(() => {
//     if (window.scrollY > scrollY) {
//       setShowNavBar(false);
//       setHoveredDropdown(null);
//     } else {
//       setShowNavBar(true);
//     }
//     setScrollY(window.scrollY);
//   }, [scrollY]);

//   useEffect(() => {
//     window.addEventListener("scroll", controlScroll);
//     return () => {
//       window.removeEventListener("scroll", controlScroll);
//     };
//   }, [controlScroll]);

//   const DropdownComp = ({ data, text, link }) => {
//     return (
//       <div
//         onMouseLeave={handleMouseLeave}
//         className="w-100 top-100 bg-white position-absolute p-3 shadow"
//       >
//         <Container>
//           <div
//             style={{
//               top: "100%",
//             }}
//             className="naaaavdropdownbox"
//           >
//             {data.map((item, index) => (
//               <Link
//                 to={item.link}
//                 key={index}
//                 className="naaaavdropdown-item2 p-3"
//               >
//                 <div className="naaaavdropdown-item p-2">{item?.text}</div>
//               </Link>
//             ))}
//             <Link to={link} className="naaaavdropdown-item2 p-3">
//               <div className="mt-2 ms-2 d-flex justify-content-start align-items-center">
//                 View All {text} <FaArrowRight className="ms-2" />
//               </div>
//             </Link>
//           </div>
//         </Container>
//       </div>
//     );
//   };

//   return (
//     <div>
//       <Navbar
//         expand="md"
//         className={`mainnnContainerNavComponent bg-white ${showNavBar ? "show" : "hide"
//           } ${showNavBar ? "" : ""} ${hoveredDropdown ? "" : ""
//           }`}
//         expanded={expanded}
//         onToggle={(expanded) => setExpanded(expanded)}
//       >
//         <Container className="position-relative">
//           <Navbar.Brand onClick={(e) => { handleNavClick(e, "/") }} href="/">
//             <img
//               src="https://res.cloudinary.com/dbklt3v4d/image/upload/v1719306812/mailStone/bu7trgfdi4qyxyelonlc.png"
//               alt="Mailstone"
//               style={{
//                 scale: "1.3"
//               }}
//               className="MainnNavBarImage"
//             />
//           </Navbar.Brand>
//           <Navbar.Toggle aria-controls="basic-navbar-nav" />
//           <Navbar.Collapse>
//             <Nav className="mx-4 MainnNavBarButtons ">
//               <div className="d-md-flex align-items-center h-100 mx-auto d-none justify-content-center">
//                 <div
//                   style={{
//                     cursor: "pointer",
//                   }}
//                   onMouseEnter={() => handleMouseEnter("services")}
//                   // onMouseLeave={() => handleMouseLeave()}
//                   className="naaaavdropdown mx-3 h-100 d-flex mt-2 justify-content-center align-items-center"
//                 >
//                   Services
//                   <DropdownIcon isHovered={hoveredItem === "services"} />
//                 </div>
//                 <div
//                   style={{
//                     cursor: "pointer",
//                   }}
//                   onMouseEnter={() => handleMouseEnter("industries")}
//                   // onMouseLeave={() => handleMouseLeave()}
//                   className="naaaavdropdown mx-3 h-100 d-flex mt-2 justify-content-center align-items-center"
//                 >
//                   Industries
//                   <DropdownIcon isHovered={hoveredItem === "industries"} />
//                 </div>
//               </div>
//               <div className="d-flex flex-column flex-md-row align-items-center justify-content-center">
//                 <div
//                   style={{
//                     cursor: "pointer",
//                   }}
//                   onMouseEnter={() => handleMouseEnter("services")}
//                   className="naaaavdropdown nav-link d-flex mt-2 d-md-none justify-content-start w-100 align-items-center"
//                 >
//                   Services
//                   <DropdownIcon isHovered={hoveredItem === "services"} />
//                 </div>
//                 <div
//                   style={{
//                     cursor: "pointer",
//                   }}
//                   onMouseEnter={() => handleMouseEnter("industries")}
//                   className="naaaavdropdown nav-link d-flex mt-2 d-md-none justify-content-start w-100 align-items-center"
//                 >
//                   Industries
//                   <DropdownIcon isHovered={hoveredItem === "industries"} />
//                 </div>
//                 <Nav.Link
//                   target="_blank"
//                   href="https://jbsinterior.in/"
//                   className={`w-100 w-md-auto ${location.pathname === "/projcompanyect" ? "active" : ""}`}
//                 >
//                   JBS Interior
//                 </Nav.Link>
//                 <Nav.Link
//                   onClick={(e) => handleNavClick(e, "/company")}
//                   href="/company"
//                   className={`w-100 w-md-auto ${location.pathname === "/projcompanyect" ? "active" : ""}`}
//                 >
//                   Company
//                 </Nav.Link>
//                 <Nav.Link
//                   onClick={(e) => handleNavClick(e, "/careers")}
//                   href="/careers"
//                   className={`w-100 w-md-auto ${location.pathname === "/careers" ? "active" : ""}`}
//                 >
//                   Careers
//                 </Nav.Link>
//               </div>
//             </Nav>
//             <div className="mainnavbarhbsbuttoncontainer">
//               <button onClick={() => navigate("/contactus")} className="mainnavBarButton m-auto">Contact Us</button>
//             </div>
//           </Navbar.Collapse>
//         </Container>
//         {hoveredDropdown && (
//           <DropdownComp
//             text={hoveredDropdown === "services" ? "services" : "industries"}
//             link={hoveredDropdown === "services" ? "/services" : "/industries"}
//             data={hoveredDropdown === "services" ? services : industries}
//           />
//         )}
//       </Navbar>
//     </div>
//   );
// };

// export default MainNavBar;

import React, { useCallback, useEffect, useState, useRef } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa6";
import "./MainNavBar.css";
import DropdownIcon from "../DropdownIcon/DropdownIcon";

const services = [
  { text: "Portfolio Solutions", link: "/services/portfoliosolutions" },
  { text: "Transaction Management", link: "/services/transaction" },
  { text: "Facility Management", link: "/services/facility" },
  { text: "Design & Project Services", link: "/services/design-project-services" },
];
const industries = [
  { text: "Office", link: "/industries/office" },
  { text: "Healthcare", link: "/industries/healthcare" },
  { text: "Industrial", link: "/industries/industrial" },
  { text: "AMC", link: "/industries/amc" },
];

const MainNavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [expanded, setExpanded] = useState(false);
  const [showNavBar, setShowNavBar] = useState(true);
  const [scrollY, setScrollY] = useState(0);
  const [hoveredDropdown, setHoveredDropdown] = useState(null);
  const dropdownRef = useRef(null);
  const timeoutRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleMouseEnter = (dropdown) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setHoveredDropdown(dropdown);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setHoveredDropdown(null);
    }, 100);
  };

  useEffect(() => {
    setHoveredDropdown(null);
  }, [expanded]);

  const handleNavClick = (e, path) => {
    e.preventDefault();
    setExpanded(false);
    navigate(path);
  };

  const controlScroll = useCallback(() => {
    if (window.scrollY > scrollY) {
      setShowNavBar(false);
      setHoveredDropdown(null);
    } else {
      setShowNavBar(true);
    }
    setScrollY(window.scrollY);
  }, [scrollY]);

  useEffect(() => {
    window.addEventListener("scroll", controlScroll);
    return () => {
      window.removeEventListener("scroll", controlScroll);
    };
  }, [controlScroll]);

  const DropdownComp = ({ data, text, link }) => {
    return (
      <div
        ref={dropdownRef}
        onMouseEnter={() => handleMouseEnter(text.toLowerCase())}
        onMouseLeave={handleMouseLeave}
        // style={{
        //   marginTop: "-16px",
        // }}
        className="w-100 top-100 bg-white position-absolute p-3 shadow"
      >
        <Container>
          <div
            style={{
              top: "100%",
            }}
            className="naaaavdropdownbox"
          >
            {data.map((item, index) => (
              <Link
                to={item.link}
                key={index}
                className="naaaavdropdown-item2 p-3"
              >
                <div className="naaaavdropdown-item p-2">{item?.text}</div>
              </Link>
            ))}
            <Link to={link} className="naaaavdropdown-item2 p-3">
              <div className="mt-2 ms-2 d-flex justify-content-start align-items-center">
                View All {text} <FaArrowRight className="ms-2" />
              </div>
            </Link>
          </div>
        </Container>
      </div>
    );
  };

  return (
    <div>
      <Navbar
        expand="md"
        className={`mainnnContainerNavComponent bg-white ${showNavBar ? "show" : "hide"
          } ${showNavBar ? "" : ""} ${hoveredDropdown ? "" : ""
          }`}
        expanded={expanded}
        onToggle={(expanded) => setExpanded(expanded)}
      >
        <Container className="position-relative">
          <Navbar.Brand onClick={(e) => { handleNavClick(e, "/") }} href="/">
            <img
              src="https://res.cloudinary.com/dbklt3v4d/image/upload/v1719306812/mailStone/bu7trgfdi4qyxyelonlc.png"
              alt="Mailstone"
              style={{
                scale: "1.3"
              }}
              className="MainnNavBarImage"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse>
            <Nav className="mx-4 MainnNavBarButtons ">
              {/* <div className="d-md-flex align-items-center h-100 mx-auto d-none justify-content-center">
                <div
                  style={{
                    cursor: "pointer",
                  }}
                  onMouseEnter={() => handleMouseEnter("services")}
                  onMouseLeave={handleMouseLeave}
                  className="naaaavdropdown mx-3 h-100 d-flex mt-2 justify-content-center align-items-center"
                >
                  Services
                  <DropdownIcon isHovered={hoveredDropdown === "services"} />
                </div>
                <div
                  style={{
                    cursor: "pointer",
                  }}
                  onMouseEnter={() => handleMouseEnter("industries")}
                  onMouseLeave={handleMouseLeave}
                  className="naaaavdropdown mx-3 h-100 d-flex mt-2 justify-content-center align-items-center"
                >
                  Industries
                  <DropdownIcon isHovered={hoveredDropdown === "industries"} />
                </div>
              </div> */}
              <div className="w-100"></div>
              <div className="d-flex flex-column flex-md-row align-items-center justify-content-center">
                {/* <div
                  style={{
                    cursor: "pointer",
                  }}
                  onMouseEnter={() => handleMouseEnter("services")}
                  onMouseLeave={handleMouseLeave}
                  className="naaaavdropdown nav-link d-flex mt-2 d-md-none justify-content-start w-100 align-items-center"
                >
                  Services
                  <DropdownIcon isHovered={hoveredDropdown === "services"} />
                </div>
                <div
                  style={{
                    cursor: "pointer",
                  }}
                  onMouseEnter={() => handleMouseEnter("industries")}
                  onMouseLeave={handleMouseLeave}
                  className="naaaavdropdown nav-link d-flex mt-2 d-md-none justify-content-start w-100 align-items-center"
                >
                  Industries
                  <DropdownIcon isHovered={hoveredDropdown === "industries"} />
                </div> */}
                <div className="d-md-flex align-items-center h-100 mx-auto d-none justify-content-center">
                <div
                  style={{
                    cursor: "pointer",
                  }}
                  onMouseEnter={() => handleMouseEnter("services")}
                  onMouseLeave={handleMouseLeave}
                  className="naaaavdropdown mx-3 h-100 d-flex  justify-content-center align-items-center"
                >
                  Services
                  <DropdownIcon isHovered={hoveredDropdown === "services"} />
                </div>
                <div
                  style={{
                    cursor: "pointer",
                  }}
                  onMouseEnter={() => handleMouseEnter("industries")}
                  onMouseLeave={handleMouseLeave}
                  className="naaaavdropdown mx-3 h-100 d-flex  justify-content-center align-items-center"
                >
                  Industries
                  <DropdownIcon isHovered={hoveredDropdown === "industries"} />
                </div>
              </div>
                <Nav.Link
                  target="_blank"
                  href="https://jbsinterior.in/"
                  className={`w-100 w-md-auto ${location.pathname === "/projcompanyect" ? "active" : ""}`}
                >
                  Residential
                </Nav.Link>
                <Nav.Link
                  onClick={(e) => handleNavClick(e, "/company")}
                  href="/company"
                  className={`w-100 w-md-auto ${location.pathname === "/projcompanyect" ? "active" : ""}`}
                >
                  Company
                </Nav.Link>
                <Nav.Link
                  onClick={(e) => handleNavClick(e, "/careers")}
                  href="/careers"
                  className={`w-100 w-md-auto ${location.pathname === "/careers" ? "active" : ""}`}
                >
                  Careers
                </Nav.Link>
              </div>
            </Nav>
            <div className="mainnavbarhbsbuttoncontainer">
              <button onClick={() => navigate("/contactus")} className="mainnavBarButton m-auto">Contact Us</button>
            </div>
          </Navbar.Collapse>
        </Container>
        {hoveredDropdown && (
          <DropdownComp
            text={hoveredDropdown}
            link={`/${hoveredDropdown}`}
            data={hoveredDropdown === "services" ? services : industries}
          />
        )}
      </Navbar>
    </div>
  );
};

export default MainNavBar;

