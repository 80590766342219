import React from "react";
import ServicesHeroSection from "../Component/ServicesHeroSection/ServicesHeroSection";
import AccordationComp from "../Component/AccordationComp/AccordationComp";
// import SuccessStories from "../Component/SussessStories/SuccessStories";
// import RelatedArticle from "../Component/RelatedArticle/RelatedArticle";
// import autocarindia from "../../Images/autocarindia.jpeg"
// import img from "../../Images/img.png"
// import GEHealthcare from "../../Images/GEHealthcare.jpeg"
import softwareimg from "../../Images/Amc.jpg"
// import TermsAndConditions from "../../industries/Component/TermsAndConditions/TermsAndConditions";
import { Container } from "react-bootstrap";
const Amc = () => {
    const AccordationData = [
        {
            header: "Portfolio Planning & Enhancement",
            text: "We align your entire commercial interiors portfolio with your business goals, crafting a unified strategy to enhance performance across all locations. Our holistic approach addresses every aspect of your interior design footprint to boost efficiency, minimize costs, and support your long-term objectives.",
            list: [
                "Comprehensive portfolio analysis and strategic planning",
                "Multi-location optimization and rationalization of interior spaces. ",
                "Scenario modeling for business growth or contraction. ",
                "Cost reduction strategies across the entire commercial interiors portfolio. ",
                "Integration of global and local market insights for strategicdecision-making. ",
            ],
        },
        {
            header: "Unified Transaction Management",
            text: "Our comprehensive approach to transactions ensures that every deal, from site selection to lease negotiation, aligns with your broader commercial interior strategy. We combine global expertise with local market insights to secure terms that advance your business goals across your entire portfolio.",
            list: [
                "Strategic lease analysis and portfolio planning.",
                "Seamless coordination of multi-location transactions and relocations.",
                "Targeted acquisition and disposition strategies for interior spaces.",
                "Efficient lease administration and critical date management for all locations.",
            ],
        },
        {
            header: "Workplace Portfolio Strategy and Execution",
            text: "We seamlessly combine workspace planning, design, and project management across your entire portfolio. Our holistic approach ensures a unified workplace strategy while allowing for local customization, creating environments that boost productivity and reflect your brand identity at every location.- Portfolio-wide workplace strategy formulation ",
            list: [
                "Standardized design guidelines with flexibility for local adaptation.",
                "Coordinated multi-site project planning and execution.",
                "Change management strategies for company-wide initiatives",
            ],
        },
        {
            header: "Portfolio Performance Analytics",
            text: "By utilizing our Vportal platform, we offer a unified solution for all your portfolio and project management requirements. Our data-driven insights support real-time decision-making, highlight trends across your portfolio, and reveal opportunities for optimization and cost savings.",
            list: [
                "Centralized data management and reporting via Vportal.",
                "Customized KPI tracking and benchmarking across the entire portfolio.",
                "Predictive analytics to enhance portfolio performance.",
                "Real-time project tracking and progress reporting across the portfolio.",
                "Integration of financial, operational, and occupancy data for comprehensive analysis.",
            ],
        },
    ];
    const accordationText = {
        leftText: "Reliable maintenance, guaranteed performance.",
        rightText:
            "At Mailstone, we offer comprehensive Annual Maintenance Contracts (AMC) to ensure the smooth operation and longevity of your facilities and equipment. Our AMC services provide proactive maintenance, regular inspections, and prompt repairs, minimizing downtime and maximizing efficiency. We understand the importance of maintaining the optimal performance of your assets, and our experienced team is dedicated to delivering cost-effective, reliable solutions tailored to your specific needs.",
    };

    return (
        <div>
            <ServicesHeroSection
                Textinjsx={
                    <>
                        Annual Maintenance Contract
                    </>
                }
                // img="https://cdn.prod.website-files.com/664e075a24cd16808e4650fc/66c7ac2a52ac84ca0be3c77b_Portfolio%20SolutionsBW.avif"
                img={softwareimg}
            />
            <AccordationComp
                leftText={accordationText.leftText}
                rightText={accordationText.rightText}
                AccordationContent={AccordationData}
            />
            {/* <TermsAndConditions/> */}
            {/* <SuccessStories stories={defaultstories} /> */}
            {/* <RelatedArticle /> */}
            <Container>
                <h2 className="PortfolioCarousel-title display-4 mb-5">Our Services</h2>
                <section className="mb-5">
                    <div className="row">
                        <div className="col-md-4 mb-3 fs-5" style={{ fontWeight: "bold" }}>
                            <ul className="list-unstyled">
                                <li>ELECTRICAL</li>
                                <li>HVAC</li>
                                <li>PLUMBING</li>
                                <li>FIRE AND SAFETY</li>
                                {/* <li>
                                    SANITATION & PEST CONTROL (Optional)
                                </li> */}
                            </ul>
                        </div>
                        <div className="col-md-4 mb-3 fs-5" style={{ fontWeight: "bold" }}>
                            <ul className="list-unstyled">
                                <li>INTERIORS</li>
                                <li>PAINTING</li>
                                <li>KITCHEN SERVICES</li>
                                <li>LEAD ROOM FOR HEALTHCARE</li>
                            </ul>
                        </div>
                        <div className="col-md-4 mb-3" style={{ fontWeight: "bold" }}>
                            <ul className="list-unstyled">

                                {/* <li>DEEP CLEANING (Optional)</li> */}
                            </ul>
                        </div>
                        <div className="col-md-4 mb-3" style={{ fontWeight: "bold" }}>
                            <ul className="list-unstyled">

                            </ul>
                        </div>
                    </div>
                </section>
            </Container>
        </div>
    );
};

export default Amc;
